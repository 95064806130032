import { useAppSelector } from "../../../store/hooks";
import MetadataComponent from "../MetadataComponent";
import usePageLanguage from "../hooks/usePageLanguage";
import React from "react";

export default function ProductMetadata({ locationProduct }: any) {
  console.log(locationProduct, "THIS IS THE LOCATION PRODUCT");

  const locale = usePageLanguage();
  const product_seo = locationProduct?.translations?.[locale].page_seo;
  const pages = useAppSelector((state) => state.builder.pages.list) as any;
  const productPage = pages.find(
    (page) => page.page_type === "products" && page.language.locale === locale
  );

  if (productPage) {
    const product_caonical = `${window.location.origin}/${locale}/${productPage?.url}/${locationProduct?.translations?.[locale].product_url}`;

    console.log(product_caonical, "Product_caonical");

    if (!product_seo) {
      return <></>;
    }

    if (product_caonical) {
      product_seo.canonical = product_caonical;
    }
  }

  if (!product_seo) {
    return <></>;
  }
  return <MetadataComponent page_seo={product_seo} />;
}
