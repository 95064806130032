import { useEffect, useState } from "react";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { pf } from "../utilities/helpers";
import { useAppSelector } from "../../../store/hooks";
import usePageLanguage from "../hooks/usePageLanguage";
import ProductImagesComponent from "./ProductImagesComponent";
import React from "react";

export default function ProductPanel({ locationProduct, id }: any) {
  const locale = usePageLanguage();

  const { increaseCartQuantity, locationProducts } = useShoppingCart();

  const [tab, setTab] = useState("descriptionTab");

  const locationProductTranslations = locationProduct?.translations?.[locale];

  // Don't move this outside of the function
  const languageTranslations = {
    et: {
      add_to_cart: "LISA OSTUKORVI",
      description: "Kirjeldus",
      delivery: "Tarne",
      delivery_time: `Tarneaeg laos: ${locationProduct.deliveryTime?.days} päeva`,
      delivery_time_with_ordering: `Tarneaeg tellimisel:
      ${locationProduct.deliveryTimeWithOrdering?.days} päeva`,
      product_code: "Tootekood",
      variation_delivery_time: `Tarneaeg laos: `,
      variation_delivery_time_with_ordering: "Tarneaeg tellimisel: ",
      variation_days: " päeva",
    },
    en: {
      add_to_cart: "ADD TO CART",
      description: "Description",
      delivery: "Delivery",
      delivery_time: `Delivery time from warehouse: ${locationProduct.deliveryTime?.days} days`,
      delivery_time_with_ordering: `Delivery time ordering:
      ${locationProduct.deliveryTimeWithOrdering?.days} days`,
      product_code: "Product code",
      variation_delivery_time: `Delivery time from warehouse: `,
      variation_delivery_time_with_ordering: "Delivery time ordering: ",
      variation_days: " days",
    },
  };

  const [cachedVariationValues, setCachedVariationValues] = useState({});
  const [filteredProducts, setFilteredProducts] = useState<any>([]);

  useEffect(() => {
    if (locationProduct && locationProduct.product.variations) {
      let value = {};
      locationProduct.product.variations.forEach((variation) => {
        value[variation.name] = "";
      });
      setCachedVariationValues(value);
    }
  }, [locationProduct]);

  useEffect(() => {
    if (locationProduct && locationProduct.product.variation_products) {
      const newFilteredProducts =
        locationProduct.product.variation_products.filter((product) => {
          return Object.keys(cachedVariationValues).every((key) => {
            if (cachedVariationValues[key] === "") return true;
            return product.variation_values.some(
              (v) => v.value === cachedVariationValues[key]
            );
          });
        });
      setFilteredProducts(newFilteredProducts);
    }
  }, [cachedVariationValues, locationProduct]);

  const getPossibleValues = (variationName) => {
    const possibleValues = new Set();

    filteredProducts.forEach((product: any) => {
      product.variation_values.forEach((v) => {
        const variation = locationProduct.product.variations.find(
          (variation) => variation.name === variationName
        );
        if (variation && v.variation_id === variation.id) {
          possibleValues.add(v.value);
        }
      });
    });

    return possibleValues;
  };

  const getPriceRange = () => {
    if (locationProduct.product.variation_products.length === 0) return null;

    // Find the lowest price and highest price but keep everything else as well in the object.

    const lowestPrice = locationProduct.product.variation_products.sort(
      (a: any, b: any) => a.price - b.price
    )[0];

    const highestPrice = locationProduct.product.variation_products.sort(
      (a: any, b: any) => b.price - a.price
    )[0];

    let lowestPriceProduct = locationProducts.find(
      (product) => product.id === lowestPrice.product_id
    );
    let highestPriceProduct = locationProducts.find(
      (product) => product.id === highestPrice.product_id
    );

    if (!lowestPriceProduct || !highestPriceProduct) {
      lowestPriceProduct = locationProducts.find(
        (product) => product.product.id === lowestPrice.product_id
      );
      highestPriceProduct = locationProducts.find(
        (product) => product.product.id === highestPrice.product_id
      );
    }

    let minPrice =
      lowestPriceProduct && lowestPriceProduct.price_with_discounts
        ? lowestPriceProduct.price_with_discounts
        : lowestPrice.price_total;

    let maxPrice =
      highestPriceProduct && highestPriceProduct.price_with_discounts
        ? highestPriceProduct.price_with_discounts
        : highestPrice.price_total;

    return { minPrice, maxPrice };
  };

  const areAllVariationsSelected = () => {
    if (!cachedVariationValues) return false;
    return Object.values(cachedVariationValues).every((value) => value !== "");
  };

  const productImagesArray: any[] = [];

  if (locationProduct?.product?.product_files) {
    for (let i = 0; i < locationProduct?.product?.product_files.length; i++) {
      productImagesArray.push({
        url: locationProduct?.product?.product_files[i].url,
      });
    }
  }

  if (
    locationProduct.product.variation_products.length > 0 &&
    locationProduct.product.product_files.length <= 0
  ) {
    // Map over each variation product and push the images to productImagesArray

    for (
      let i = 0;
      i < locationProduct.product.variation_products.length;
      i++
    ) {
      const productFound = locationProducts.find(
        (product) =>
          product.id ===
          locationProduct.product.variation_products[i].product_id
      );

      productFound?.product.product_files.map((file) =>
        productImagesArray.push(file)
      );
    }
  }

  return (
    <div className="panel" id="productPanel">
      <div className="panel-body container">
        <div className="col-xs-12">
          <div className="imagesContainer">
            <h1 className="product-name">{locationProductTranslations.name}</h1>
            <div className="web-product-image">
              {!locationProduct.product.variations ||
              locationProduct.product.variations.length === 0 ? (
                <ProductImagesComponent
                  id={id}
                  slides={locationProduct.product.product_files}
                  options={{ align: "start" }}
                />
              ) : null}

              {locationProduct.product.variations.length > 0 &&
                !areAllVariationsSelected() && (
                  <ProductImagesComponent
                    id={id}
                    slides={
                      locationProduct.product.product_files.length > 0
                        ? locationProduct.product.product_files
                        : productImagesArray
                    }
                    options={{ align: "start" }}
                  />
                )}

              {locationProduct.product.variations.length > 0 &&
                areAllVariationsSelected() && (
                  <>
                    {locationProducts.find(
                      (x) => x.id === filteredProducts[0]?.product_id
                    )?.product.product_files.length > 0 && (
                      <>
                        <ProductImagesComponent
                          id={id}
                          slides={
                            locationProducts.find(
                              (x) => x.id === filteredProducts[0]?.product_id
                            )?.product.product_files
                          }
                          options={{ align: "start" }}
                        />
                      </>
                    )}
                    {locationProducts.find(
                      (x) => x.product.id === filteredProducts[0]?.product_id
                    )?.product.product_files.length > 0 &&
                      (locationProducts.find(
                        (x) => x.id === filteredProducts[0]?.product_id
                      )?.product.product_files.length <= 0 ||
                        locationProducts.find(
                          (x) => x.id === filteredProducts[0]?.product_id
                        )?.product.product_files.length === undefined) && (
                        <>
                          <ProductImagesComponent
                            id={id}
                            slides={
                              locationProducts.find(
                                (x) =>
                                  x.product.id ===
                                  filteredProducts[0]?.product_id
                              )?.product.product_files
                            }
                            options={{ align: "start" }}
                          />
                        </>
                      )}
                    {(locationProducts.find(
                      (x) => x.id === filteredProducts[0]?.product_id
                    )?.product.product_files.length <= 0 ||
                      locationProducts.find(
                        (x) => x.product.id === filteredProducts[0]?.product_id
                      )?.product.product_files.length <= 0) &&
                      productImagesArray.length > 0 && (
                        <>
                          <ProductImagesComponent
                            id={id}
                            slides={productImagesArray}
                            options={{ align: "start" }}
                          />
                        </>
                      )}
                  </>
                )}
            </div>
          </div>
          <div className="dataContainer">
            {locationProduct.product.variations &&
              !areAllVariationsSelected() && (
                <h1 className="product-name inline-block">
                  {locationProductTranslations.name}
                </h1>
              )}

            {(!locationProduct.product.variations ||
              locationProduct.product.variations.length === 0) && (
              <h1 className="product-name inline-block">
                {locationProductTranslations.name}
              </h1>
            )}

            {locationProduct &&
              locationProduct.product &&
              locationProduct.product.variations &&
              locationProduct.product.variations.length > 0 &&
              filteredProducts.length > 0 &&
              areAllVariationsSelected() && (
                <h1 className="product-name inline-block">
                  {locationProducts &&
                  locationProduct.product &&
                  locationProduct.product.variations &&
                  locationProduct.product.variations.length > 0 &&
                  filteredProducts[0] &&
                  locationProducts.find(
                    (x) => x.id === filteredProducts[0]?.product_id
                  )?.translations?.[locale]?.name
                    ? locationProducts.find(
                        (x) => x.id === filteredProducts[0]?.product_id
                      )?.translations?.[locale]?.name
                    : locationProducts.find(
                        (x) => x.product.id === filteredProducts[0]?.product_id
                      )?.translations?.[locale]?.name}
                </h1>
              )}

            {locationProduct.posProductCode &&
              locationProduct.posProductCode != "" &&
              (!locationProduct.product.variations ||
                locationProduct.product.variations.length === 0) && (
                <div>
                  <span
                    className="product-code"
                    style={{ fontSize: "15px", fontWeight: "400" }}
                  >
                    {languageTranslations[locale]?.product_code}:{" "}
                    {locationProduct.posProductCode}
                  </span>
                </div>
              )}

            {locationProduct &&
              locationProduct.product &&
              locationProduct.posProductCode &&
              locationProduct.product.variations &&
              locationProduct.product.variations.length > 0 &&
              !areAllVariationsSelected() && (
                <div>
                  <span
                    className="product-code"
                    style={{ fontSize: "15px", fontWeight: "400" }}
                  >
                    {languageTranslations[locale]?.product_code}:
                    {locationProduct.posProductCode}
                  </span>
                </div>
              )}

            {locationProduct.product.variations &&
              locationProduct.product.variations.length > 0 &&
              (locationProducts.find(
                (x) => x.id === filteredProducts[0]?.product_id
              )?.posProductCode !== "" ||
                locationProducts.find(
                  (x) => x.product.id === filteredProducts[0]?.product_id
                )?.posProductCode !== "") &&
              areAllVariationsSelected() && (
                <div>
                  <span
                    className="product-code"
                    style={{ fontSize: "15px", fontWeight: "400" }}
                  >
                    {locationProducts.find(
                      (x) => x.id === filteredProducts[0]?.product_id
                    ) &&
                      locationProducts.find(
                        (x) => x.id === filteredProducts[0]?.product_id
                      )?.posProductCode !== "" && (
                        <div>
                          {languageTranslations[locale]?.product_code}:{" "}
                          {
                            locationProducts.find(
                              (x) => x.id === filteredProducts[0]?.product_id
                            )?.posProductCode
                          }
                        </div>
                      )}
                    {locationProducts.find(
                      (x) => x.product.id === filteredProducts[0]?.product_id
                    ) &&
                      locationProducts.find(
                        (x) => x.product.id === filteredProducts[0]?.product_id
                      )?.posProductCode !== "" && (
                        <div>
                          {languageTranslations[locale]?.product_code}:{" "}
                          {
                            locationProducts.find(
                              (x) =>
                                x.product.id === filteredProducts[0]?.product_id
                            )?.posProductCode
                          }
                        </div>
                      )}
                  </span>
                </div>
              )}

            {locationProduct.product.variations &&
              !areAllVariationsSelected() && (
                <div
                  className="productPrice"
                  // style={
                  //   locationProduct.adjustments &&
                  //   locationProduct.adjustments?.length > 0
                  //     ? { textDecoration: "line-through", lineHeight: "1" }
                  //     : {}
                  // }
                >
                  {/* Display the price range here */}

                  {getPriceRange()?.minPrice !== getPriceRange()?.maxPrice && (
                    <>
                      {pf(getPriceRange()?.minPrice || 0)} &euro; -{" "}
                      {pf(getPriceRange()?.maxPrice || 0)} &euro;
                    </>
                  )}

                  {getPriceRange()?.minPrice === getPriceRange()?.maxPrice && (
                    <>{pf(getPriceRange()?.minPrice || 0)} &euro;</>
                  )}
                </div>
              )}

            {locationProduct.product.variations &&
              locationProduct.product.variations.length > 0 &&
              areAllVariationsSelected() && (
                <div
                  className="productPrice"
                  style={
                    locationProducts.find(
                      (x) => x.id === filteredProducts[0]?.product_id
                    )?.adjustments &&
                    locationProducts.find(
                      (x) => x.id === filteredProducts[0]?.product_id
                    )?.adjustments?.length > 0
                      ? { textDecoration: "line-through", lineHeight: "1" }
                      : {}
                  }
                >
                  {locationProducts.find(
                    (x) => x.id === filteredProducts[0]?.product_id
                  )?.price_total ? (
                    <>
                      {pf(
                        locationProducts.find(
                          (x) => x.id === filteredProducts[0]?.product_id
                        )?.price_total || 0
                      )}
                    </>
                  ) : (
                    <>
                      {pf(
                        locationProducts.find(
                          (x) =>
                            x.product.id === filteredProducts[0]?.product_id
                        )?.price_total || 0
                      )}
                    </>
                  )}{" "}
                  &euro;
                  {/* Need to check if minPrice and maxPrice is the same, then just display one price value */}
                </div>
              )}

            {(!locationProduct.product.variations ||
              locationProduct.product.variations.length === 0) && (
              <div
                className="productPrice"
                style={
                  locationProduct.adjustments &&
                  locationProduct.adjustments?.length > 0
                    ? { textDecoration: "line-through", lineHeight: "1" }
                    : {}
                }
              >
                {pf(locationProduct.price_total)} &euro;
              </div>
            )}

            {locationProduct.product.variations &&
              areAllVariationsSelected() &&
              locationProduct.product.variations.length > 0 &&
              locationProducts.find(
                (x) => x.id === filteredProducts[0]?.product_id
              )?.adjustments.length > 0 && (
                <div className="discount_price_box shop-controlled-border-radius discount-price-color">
                  {locationProducts.find(
                    (x) => x.id === filteredProducts[0]?.product_id
                  )?.price_with_discounts
                    ? pf(
                        Number(
                          locationProducts.find(
                            (x) => x.id === filteredProducts[0]?.product_id
                          )?.price_with_discounts
                        )
                      ) + `€`
                    : ""}{" "}
                  {locationProducts
                    .find((x) => x.id === filteredProducts[0]?.product_id)
                    ?.adjustments?.map((adjustment, i) => {
                      const string = `${i > 0 ? ", " : ""}${
                        adjustment.display_name
                      } ${
                        adjustment.value.toString().startsWith("-") ? "" : "+"
                      }${adjustment.value}%`;

                      return string;
                    })}
                </div>
              )}

            {(!locationProduct.product.variations ||
              locationProduct.product.variations.length === 0) &&
            locationProduct.price_with_discounts &&
            locationProduct.adjustments?.length > 0 ? (
              <div className="discount_price_box shop-controlled-border-radius discount-price-color">
                {pf(Number(locationProduct.price_with_discounts))} &euro;{" "}
                {locationProduct.adjustments?.map((adjustment, i) => {
                  const string = `${i > 0 ? ", " : ""}${
                    adjustment.display_name
                  } ${adjustment.value.toString().startsWith("-") ? "" : "+"}${
                    adjustment.value
                  }%`;

                  return string;
                })}
              </div>
            ) : (
              ""
            )}

            {(!locationProduct.product.variations ||
              locationProduct.product.variations.length === 0) && (
              <div>
                <button
                  className="btn btn-primary addProductToCart shop-controlled-border-radius"
                  style={{ fontSize: "14px" }}
                  onClick={(e) => {
                    e.stopPropagation();

                    increaseCartQuantity(locationProduct.id);
                  }}
                >
                  {languageTranslations[locale]?.add_to_cart}
                </button>
              </div>
            )}

            {locationProduct.product.variations &&
              locationProduct.product.variations.length >= 1 && (
                <div
                  style={{
                    marginTop: "15px",
                  }}
                >
                  <button
                    disabled={!areAllVariationsSelected()}
                    className="btn btn-primary addProductVariableToCart shop-controlled-border-radius"
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                      fontWeight: "600",
                      padding: "5px 15px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();

                      if (!filteredProducts[0].id) return;

                      const variationValues = JSON.stringify({
                        id: filteredProducts[0].id,
                        p_p_id: filteredProducts[0].parent_product_id,
                        v_v: filteredProducts[0].variation_values.map((v) => ({
                          id: v.id,
                          value: v.value,
                        })),
                      });

                      increaseCartQuantity(
                        locationProducts.find(
                          (x) =>
                            x.product.id === filteredProducts[0]?.product_id
                        )!.id,

                        variationValues
                      );
                    }}
                  >
                    {languageTranslations[locale]?.add_to_cart}
                  </button>
                </div>
              )}

            {locationProduct.product.variations && (
              <div className="product-variants">
                {locationProduct.product.variations.map((variation) => {
                  const possibleValues = getPossibleValues(variation.name);

                  return (
                    <div
                      className="product-variant"
                      style={{ marginTop: "10px" }}
                    >
                      <span style={{ fontSize: "15px", fontWeight: "600" }}>
                        {variation.locale_translations[locale]?.name}
                      </span>

                      <div className="product-variant-values">
                        {variation.variation_values.map((value) => {
                          const isDisabled = !possibleValues.has(value.value);

                          const variationHasProduct =
                            locationProduct.product.variation_products.find(
                              (x) => {
                                return x.variation_values.find(
                                  (v) => v.value === value.value
                                );
                              }
                            );

                          return (
                            <button
                              disabled={
                                locationProduct.product.variations.length > 1
                                  ? isDisabled
                                  : false || !variationHasProduct
                              }
                              onClick={(e) => {
                                e.stopPropagation();

                                // Check if the value is already selected, then remove it
                                if (
                                  cachedVariationValues[variation.name] ===
                                  value.value
                                ) {
                                  setCachedVariationValues({
                                    ...cachedVariationValues,
                                    [variation.name]: "",
                                  });
                                  return;
                                }

                                setCachedVariationValues({
                                  ...cachedVariationValues,
                                  [variation.name]: value.value,
                                });
                              }}
                              className={`btn btn-primary product-variant-value shop-controlled-border-radius ${
                                cachedVariationValues[variation.name] ===
                                value.value
                                  ? "product-variant-value-selected"
                                  : ""
                              }`}
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                marginLeft: "5px",
                                margin: "0px",
                                minWidth: "45px",
                                padding: "5px 15px",
                                backgroundColor:
                                  cachedVariationValues[variation.name] ===
                                  value.value
                                    ? "var(--shop-theme-color)!important"
                                    : "",
                              }}
                            >
                              {value.locale_translations[locale]?.value}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="pt-15">
              <div className="nav-tabs-horizontal" data-plugin="tabs">
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link${
                        tab == "descriptionTab" ? " active" : ""
                      }`}
                      onClick={() => {
                        setTab("descriptionTab");
                      }}
                      href={undefined}
                      aria-controls="descriptionTab"
                      role="tab"
                    >
                      {languageTranslations[locale]?.description}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link${
                        tab == "deliveryTab" ? " active" : ""
                      }`}
                      onClick={() => {
                        setTab("deliveryTab");
                      }}
                      href={undefined}
                      aria-controls="deliveryTab"
                      role="tab"
                    >
                      {languageTranslations[locale]?.delivery}
                    </a>
                  </li>
                </ul>
                <div className="tab-content pt-20">
                  <div
                    className={`tab-pane text-left${
                      tab == "descriptionTab" ? " active" : ""
                    }`}
                    id="descriptionTab"
                    role="tabpanel"
                  >
                    {!locationProduct.product.variations ||
                    locationProduct.product.variations.length == 0 ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: locationProductTranslations.description,
                        }}
                      ></div>
                    ) : null}

                    {locationProduct.product.variations.length > 0 &&
                      !areAllVariationsSelected() && (
                        <div
                          style={{ fontSize: "16px" }}
                          dangerouslySetInnerHTML={{
                            __html: locationProductTranslations.description,
                          }}
                        ></div>
                      )}

                    {locationProduct.product.variations.length > 0 &&
                      areAllVariationsSelected() && (
                        <div
                          style={{ fontSize: "16px" }}
                          dangerouslySetInnerHTML={{
                            __html: locationProducts.find(
                              (x) => x.id === filteredProducts[0]?.product_id
                            )?.translations[locale].description
                              ? locationProducts.find(
                                  (x) =>
                                    x.id === filteredProducts[0]?.product_id
                                )?.translations[locale].description
                              : locationProductTranslations.description,
                          }}
                        ></div>
                      )}
                  </div>

                  <div
                    className={`tab-pane text-left${
                      tab == "deliveryTab" ? " active" : ""
                    }`}
                    id="deliveryTab"
                    role="tabpanel"
                  >
                    {!locationProduct.product.variations ||
                    locationProduct.product.variations.length === 0 ? (
                      <>
                        {locationProduct.deliveryTime && (
                          <div className="deliveryTime">
                            {languageTranslations[locale]?.delivery_time}
                          </div>
                        )}
                        {locationProduct.deliveryTimeWithOrdering && (
                          <div className="deliveryTimeWithOrdering">
                            {
                              languageTranslations[locale]
                                ?.delivery_time_with_ordering
                            }
                          </div>
                        )}

                        {locationProductTranslations.deliveryInfo}
                      </>
                    ) : null}

                    {locationProduct.product.variations &&
                      locationProduct.product.variations.length > 0 &&
                      !areAllVariationsSelected() && (
                        <>
                          {locationProduct.deliveryTime && (
                            <div className="deliveryTime">
                              {languageTranslations[locale]?.delivery_time}
                            </div>
                          )}
                          {locationProduct.deliveryTimeWithOrdering && (
                            <div className="deliveryTimeWithOrdering">
                              {
                                languageTranslations[locale]
                                  ?.delivery_time_with_ordering
                              }
                            </div>
                          )}

                          {locationProductTranslations.deliveryInfo}
                        </>
                      )}

                    {locationProduct.product.variations &&
                      locationProduct.product.variations.length > 0 &&
                      areAllVariationsSelected() && (
                        <>
                          {locationProducts.find(
                            (x) => x.id === filteredProducts[0]?.product_id
                          )?.deliveryTime && (
                            <div className="deliveryTime">
                              {
                                languageTranslations[locale]
                                  ?.variation_delivery_time
                              }{" "}
                              {
                                locationProducts.find(
                                  (x) =>
                                    x.id === filteredProducts[0]?.product_id
                                )?.deliveryTime.days
                              }{" "}
                              {languageTranslations[locale]?.variation_days}
                            </div>
                          )}

                          {locationProducts.find(
                            (x) => x.id === filteredProducts[0]?.product_id
                          )?.deliveryTimeWithOrdering && (
                            <div className="deliveryTimeWithOrdering">
                              {
                                languageTranslations[locale]
                                  ?.variation_delivery_time_with_ordering
                              }
                              {
                                locationProducts.find(
                                  (x) =>
                                    x.id === filteredProducts[0]?.product_id
                                )?.deliveryTimeWithOrdering?.days
                              }{" "}
                              {languageTranslations[locale]?.variation_days}
                            </div>
                          )}
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
