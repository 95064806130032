import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage2Avaleht from "./pages/Webpage2Avaleht";
import Webpage12Lehte_ei_leitud from "./pages/Webpage12Lehte_ei_leitud";
import Webpage14Tingimused from "./pages/Webpage14Tingimused";

var baseURL = 'https://web.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOThiZDhmYTE1NDgyNzYwNWJhOThmZjgzNGYxZTg3M2UzYzMzYzgwYTg2MTU2OTZkYzM3OGI5N2VhNGY2NGRiZjU5ODkyNWI1MmExZDliMTMiLCJpYXQiOjE3MjY3MzU3ODIuMzM2MDg4LCJuYmYiOjE3MjY3MzU3ODIuMzM2MDkyLCJleHAiOjE3MjY4MjIxODIuMzMyNDA1LCJzdWIiOiI0MCIsInNjb3BlcyI6W119.Fo-kTFyY8C1JKUu93w7dd-wYbs9EFl2Jpa_Fu0GVKHfQ1HGWkskJyO1-ObCXew3SEnYC7TEdR9ch1F5rExj6iqoGjOOztHZlxKrt2gHA7voUHz0tlo36O4qmqmq8QA40iGzM3EjSIA0hNtfybJDl7-TxbNPV8Fd3k9EWKgAdew2ZIVlYuzuPk8kdF7JJxNw8Y-d3xVs9XoZlqTdA1Lv9LDxQTYLsoQN4WhW3OHpAeFCMNB5HkwcoB77km-WOixijyrh8XINZ-iQEKVzFlL97PJtiA8-ioDxUZC9Zav7clCDEp2cnd9o-relIuhPE_rOoibRDtGRQBXPJpMQ-alsL8zo70T4atGQqvvC55lXr5Q-VZu6vHG1vwlq3Kw4mflMYffoIvoLA8ofZqDAcEHLv5CjPoUE9GuRhle9yFxyrXPvcMXgz9i4r6RCTCLV_Yftr7h8RGneau_mEb1qNJH2mDmVP84meA2r2AMqrePIJOT0YTh0Cuv_7OBNaF2mzB3I-dpz7FChq68xASbKjRhMZxGNF30bOnXtIlQmVcTyAaR0oPtAEJzIQaz6ZwPZ4MxhHFSI8hLu6mixIA4x2J7uYmT1Kc81L94URUMKlKr5qomaU5u8FtzTVbYZdP-UEtn31c1IZtUrHZOuRPDhwYEBADp-jb2ejNtVYZumyFDsAYn4',
    'Access-api-key': "eyJpdiI6ImFGYjZHSzZNcnhWT0FVU3NhUW5XN0E9PSIsInZhbHVlIjoiM1VLcHREdU1RNEN2cERGSEp1NzJzdz09IiwibWFjIjoiOGRkNWU5NWJmMjMwYzYzYjQzOGRjMzRlNjA2ZThmZWUwMmIzNTliNzYwZWUyZmJmMmUwMGI5YTA0MWNkNDgyMSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IjdjQ2lNUjRlbHVWcnh4czRYZVVIYkE9PSIsInZhbHVlIjoiL05SdlVwNkpiUzQzVFN3ekRVbDR2Zz09IiwibWFjIjoiZGE2Y2JlNGE1MDZkOTEyNTNjMGI3NzJlMDQ3ZDRlODdlYjY3NjRkNGVhYTA0NzgxODAyMTczMTZjYmEwYTM0YyIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-KHPWXL9"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage2Avaleht />} exact />;
                                                                    <Route path="/et/*" element={<Webpage12Lehte_ei_leitud />} exact />;
                                                <Route path="*" element={<Webpage12Lehte_ei_leitud />} exact />;
                                                                    <Route path="/et/tingimused" element={<Webpage14Tingimused />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}