import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { ProductDetailComponent } from "../interfaces/base";

type PropType = {
  slide: any;
  selected: boolean;
  index: number;
  onClick: () => void;
  isFullscreen?: boolean;

  componentId: any;
};

export const Thumb: React.FC<PropType> = (props) => {
  const {
    slide,
    selected,
    index,
    onClick,
    isFullscreen,

    componentId,
  } = props;

  const component = useAppSelector(
    (state) => state.builder.structure.components[componentId]
  ) as any;

  const [thumbHeight, setThumbHeight] = useState(
    component.attributes.thumbnailHeightUnit &&
      component.attributes.thumbnailHeightUnit
      ? `${component.attributes.thumbnailHeightNumber}${component.attributes.thumbnailHeightUnit}`
      : "120px"
  );

  const [thumbnailWidthFlex, setThumbnailWidthFlex] = useState(
    component.attributes.thumbnailMediaWidth
      ? component.attributes.thumbnailMediaWidth
      : "19"
  );

  useEffect(() => {
    setThumbHeight(
      component.attributes.thumbnailHeightNumber &&
        component.attributes.thumbnailHeightUnit
        ? `${component.attributes.thumbnailHeightNumber}${component.attributes.thumbnailHeightUnit}`
        : "120px"
    );

    setThumbnailWidthFlex(
      component.attributes.thumbnailMediaWidth
        ? component.attributes.thumbnailMediaWidth
        : "19"
    );
  }, [component]);

  return (
    <>
      <style>
        {`
        
        @media (min-width: 576px) {
          .embla-thumbs__slide {
            flex: 0 0 ${thumbnailWidthFlex}%;
          }
         
        }

      `}
      </style>

      <div
        className={"embla-thumbs__slide".concat(
          selected ? " embla-thumbs__slide--selected" : ""
        )}
        style={{
          height: thumbHeight,
          flex: `0 0 ${thumbnailWidthFlex}%`,
        }}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
          type="button"
          className="embla-thumbs__slide__number"
          style={{
            backgroundImage: slide.url ? `url(${slide.url})` : slide.src,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
            width: "100%",
            border: "none",
            outline: "none",
            cursor: "pointer",
          }}
        ></button>
      </div>
    </>
  );
};
