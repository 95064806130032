import { useEffect, useState } from "react";

type ImageURL = string;
type SourcesLoaded = ImageURL[];

export default function useLazyLoad(
  elementRef: React.RefObject<HTMLElement>,
  src: string | string[]
): SourcesLoaded {
  const [sourcesLoaded, setSourcesLoaded] = useState<SourcesLoaded>([]);

  useEffect(() => {
    let urls: ImageURL[] = [];
    if (src === undefined) return;

    if ((src as string).includes("url(")) {
      // If multiple URLs, split the string and remove empty strings
      urls = (src as string)
        .split('url("')
        .map((url) => url.replace('")', ""))
        .filter(Boolean) as ImageURL[];
    } else {
      // If single URL, just add it to the array
      urls.push(src as ImageURL);
    }

    if (urls.length > 0 && urls[0] !== "") {
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              urls.forEach((url, index) => {
                if (index === 0) {
                  setSourcesLoaded([]);
                }

                const img = new Image();
                img.src = url;

                img.onload = () => {
                  setSourcesLoaded((prevSources) => [...prevSources, url]);
                  intersectionObserver.unobserve(entry.target);
                };
              });
            }
          });
        },
        { threshold: 0 }
      );

      if (elementRef.current) {
        intersectionObserver.observe(elementRef.current);
      }

      return () => {
        intersectionObserver.disconnect();
      };
    }
  }, [elementRef, src]);

  return sourcesLoaded;
}
