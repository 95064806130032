import { pf } from "../utilities/helpers";
import {
  DeliveryMethod,
  useShoppingCart,
} from "../context/ShoppingCartContext";
import usePageLanguage from "../hooks/usePageLanguage";
import React from "react";

interface Props {
  deliveryMethod: DeliveryMethod | null;
  setShowDeliveryModal: (val: boolean) => void;
  setModalDeliveryMethod: (val: DeliveryMethod | null) => void;
  deliveryMethods: DeliveryMethod[];
}

export default function CheckoutDeliveryMethodButton({
  deliveryMethods,
  deliveryMethod,
  setShowDeliveryModal,
  setModalDeliveryMethod,
}: Props) {
  const { cartItems } = useShoppingCart();

  const lang = usePageLanguage();
  return (
    <div className="col-xs-12 margin-bottom-10">
      <button
        disabled={cartItems.length === 0}
        className="btn btn-primary-outline text-left checkoutDeliveryButton shop-controlled-border-radius"
        style={{ width: "100%" }}
        onClick={() => {
          setShowDeliveryModal(true);
          setModalDeliveryMethod(deliveryMethod);
        }}
      >
        {deliveryMethod?.locale_translations[lang]?.name}
        <span className="pull-right">
          {" "}
          {deliveryMethods.length > 1 && (
            <>{pf(deliveryMethod!.deliveryMethodPrice.price)} &euro;</>
          )}
          {deliveryMethods.length === 1 &&
            deliveryMethod?.type !== "pickup" && (
              <>{pf(deliveryMethod!.deliveryMethodPrice.price)} &euro;</>
            )}
        </span>
      </button>
    </div>
  );
}
