import React from "react";
import { useAppSelector } from "../../store/hooks";

import { defaultComponents } from "./interfaces/DefaultComponents";

import "./MegaMenuComponent.css";
import ContentRenderWebpageComponent from "./ContentRenderWebpageComponent";

export default function MegaMenuComponent({
  id,
  menuOpen,
  setMegaMenuOpen,
  leftPosition,
  topPosition,
}: any) {
  const templates = useAppSelector((state) => state.builder.templates);
  const components = useAppSelector(
    (state) => state.builder.structure.components
  );

  const megamenuTemplate = templates.find((x) => x.id === id);

  if (!megamenuTemplate) {
    return <></>;
  }

  const megamenuTemplateComponent =
    components[megamenuTemplate?.webpage_component_id];

  if (!megamenuTemplateComponent) {
    return <></>;
  }

  const ComponentToRender = defaultComponents[megamenuTemplateComponent.type];

  return (
    <div
      className={`megamenu_component ${menuOpen ? "visible" : ""}`}
      onMouseEnter={() => {
        setMegaMenuOpen(true);
      }}
      onMouseLeave={() => {
        setMegaMenuOpen(false);
      }}
      style={{
        visibility: menuOpen ? "visible" : "hidden",
        height: "auto",
        width: megamenuTemplateComponent?.attributes?.content_width_size
          ? megamenuTemplateComponent?.attributes?.content_width_size +
            megamenuTemplateComponent?.attributes?.content_width_unit
          : "auto",
        left: leftPosition + "px",
        top: topPosition + "px",
      }}
    >
      <ComponentToRender
        {...megamenuTemplateComponent}
        key={megamenuTemplateComponent.id}
      />
    </div>
  );
}
