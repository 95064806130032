import HomeIcon from "@mui/icons-material/Home";
import usePageLanguage from "../hooks/usePageLanguage";
import { useAppSelector } from "../../../store/hooks";
import { getPageLocaleUrl } from "../utilities/helpers";
import React from "react";

export default function ProductBreadcrumbs({ locationProduct }: any) {
  const lang = usePageLanguage();
  const pages = useAppSelector((state) => state.builder.pages.list);

  var parentCategories = locationProduct.productCategories.filter((pc: any) => {
    return pc.parent_id == null;
  });

  const languages = useAppSelector((state) => state.builder.languageSettings.activeLanguages);


  const productsPage = pages.find(
    (page) => page.page_type === "products" && page.language.locale === lang
  );
  var languageProductsHref = productsPage
    ? getPageLocaleUrl(productsPage, languages).replace(/:id$/, "")
    : "/et/tooted";
  if (languageProductsHref.substring(0, 1) !== "/") {
    languageProductsHref = "/" + languageProductsHref;
  }

  var productParentCategoriesArr: any = [];
  function recursiveCategoryChildren(parentCategory: any, depth: number) {
    const childCategories = locationProduct.productCategories.filter(
      (pc: any) => {
        return pc.parent_id == parentCategory.id;
      }
    );
    childCategories.forEach((childParentCategory: any) => {
      if (!productParentCategoriesArr[depth]) {
        productParentCategoriesArr[depth] = [];
      }
      productParentCategoriesArr[depth].push(childParentCategory);
      recursiveCategoryChildren(childParentCategory, depth + 1);
    });
    parentCategory.childCategories = childCategories;
  }
  parentCategories.forEach((parentCategory: any) => {
    if (productParentCategoriesArr.length === 0) {
      productParentCategoriesArr[0] = [];
    }
    productParentCategoriesArr[0].push(parentCategory);
    recursiveCategoryChildren(parentCategory, 1);
  });

  return (
    <div className="container">
      <div className="page-header page-detail-page-header">
        <div className="product-detail-page-nav">
          <a className="breadcrumb icon" href="/" role="button">
            <HomeIcon />{" "}
          </a>
          <b className="web-category-link-sepparator">/</b>
          {productParentCategoriesArr &&
            productParentCategoriesArr.length &&
            productParentCategoriesArr
              .map((categories: any, index: string) => {
                return categories
                  .map((category: any) => {
                    return (
                      <a
                        key={category.id}
                        className="breadcrumb"
                        href={`${languageProductsHref}?category=` + category.id}
                        role="button"
                      >
                        {category.locale_translations?.[lang].name}{" "}
                      </a>
                    );
                  })
                  .reduce((prev: any, curr: any) => [prev, " , ", curr]);
              })
              .reduce((prev: any, curr: any) => [
                prev,
                <b
                  key={curr.key + "" + prev.key}
                  className="web-category-link-sepparator"
                >
                  /
                </b>,
                curr,
              ])}
        </div>
      </div>
    </div>
  );
}
