import React, { useEffect, useState } from "react";
import { Close, Menu } from "@mui/icons-material";
import styled from "@emotion/styled";
import ErrorFallbackComponent from "./ErrorFallbackComponent";
import NavItemComponent from "./NavItemComponent";
import { useAppSelector } from "../../store/hooks";
import { defaultComponents } from "./interfaces/DefaultComponents";
import "./Default.css";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function MobileNavigation({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: any) {
  const structure = useAppSelector((state) => state.builder.structure);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const templates = useAppSelector((state) => state.builder.templates);
  const components = useAppSelector(
    (state) => state.builder.structure.components
  );

  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;

  const navAttributes = component.attributes;

  const mobileMenuTemplate = templates.find(
    (x) => x.id === navAttributes.mobileMenuTemplateId
  );

  let mobileMenuTemplateComponent;

  if (mobileMenuTemplate) {
    mobileMenuTemplateComponent =
      components[mobileMenuTemplate.webpage_component_id];
  }

  const ComponentToRender =
    defaultComponents[mobileMenuTemplateComponent?.type];

  useEffect(() => {
    const header = document.querySelector(
      ".builder-component__header"
    ) as HTMLElement;

    const containerElement = document.getElementById(component.parent_id);

    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.overflowX = "hidden";

      if (header) {
        header.classList.add("remove__backdrop");
      }

      if (containerElement) {
        containerElement.style.position = "unset";
      }
    } else {
      document.body.style.overflow = "auto";
      document.body.style.overflowX = "hidden";
      if (containerElement) {
        containerElement.style.position = "relative";
      }

      header.classList.remove("remove__backdrop");
    }
  }, [mobileMenuOpen]);

  return (
    <>
      <style>
        {`
     .mobilemenu-open-template .container {
      margin-left: ${
        navAttributes.templateAlignContent === "left" ? "0" : "auto"
      };
      margin-right: ${
        navAttributes.templateAlignContent === "right" ? "0" : "auto"
      };
     }

     .accordion-content .container.g-0 {
      margin-left: 0;
     }


     .mobilemenu-open-template .container .row {
      min-height: 100%;
      max-height: 100dvh;
      height: 100dvh;
      overflow: scroll;
     }

     .mobilemenu-open-template .container .row .accordion-content .container .row {
      height: auto;
      scrollbar-width: none;
      overflow: hidden;
     }
 


    `}
      </style>

      <div
        className="mobilemenutriggers"
        style={{ display: "flex", alignItems: "center", overflow: "hidden" }}
      >
        {mobileMenuOpen &&
          (!navAttributes.mobileMenuTemplateId ||
            navAttributes.mobileMenuTemplateId === null) && (
            <div
              className="mobile-menu"
              style={{
                marginRight: "10px",
                position: "absolute",
                top: "0",
                left: "0",
                background: "#fff",
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                padding: "60px 30px",
                fontSize: "24px !important",
                zIndex: "999999",
              }}
            >
              <ul className="mobile-menu-unordered-list">
                {childWebpageComponentRelations.map((componentRelation) => {
                  const childComponent =
                    structure.components[componentRelation.child_id];
                  if (childComponent && childComponent.type !== "editor") {
                    return (
                      <ErrorFallbackComponent
                        key={
                          childComponent.id + "_errorBoundary_" + Math.random()
                        }
                      >
                        <NavItemComponent
                          {...(childComponent as any)}
                          key={childComponent.id}
                          isMobile={true}
                        />
                      </ErrorFallbackComponent>
                    );
                  }
                })}
              </ul>
              <Close
                onClick={() => setMobileMenuOpen(false)}
                style={{
                  fontSize: "24px",
                  color: "#000",
                  marginTop: "4px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "40px",
                  top: "40px",
                }}
              />
            </div>
          )}

        {mobileMenuTemplateComponent && mobileMenuOpen && (
          <div
            style={{
              display: "flex",
              height: "fit-content",
              minHeight: "100dvh",
              backgroundColor: navAttributes.templateBackgroundcolor
                ? navAttributes.templateBackgroundcolor
                : "#fff",
              maxHeight: "100dvh",
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              zIndex: 99,
            }}
            className={`mobilemenu-open-template ${
              mobileMenuOpen && mobileMenuTemplateComponent
                ? "mobilemenu-open-template-open"
                : "mobilemenu-open-template-closed"
            }`}
            onClick={(e) => {
              if (
                (e.target as HTMLElement).classList.contains(
                  "mobilemenu-open-template-open"
                )
              ) {
                setMobileMenuOpen(false);
              }
            }}
          >
            <ComponentToRender
              {...mobileMenuTemplateComponent}
              key={mobileMenuTemplateComponent.id}
            />

            <Close
              onClick={() => {
                setMobileMenuOpen(false);
              }}
              style={{
                fontSize: "24px",
                color: "#000",
                marginTop: "4px",
                cursor: "pointer",
                position: "absolute",
                right: "40px",
                top: "20px",
                zIndex: 9999999999,
              }}
            />
          </div>
        )}
        {mobileMenuOpen === false ? (
          <Menu
            onClick={() => setMobileMenuOpen(true)}
            style={{
              color: navAttributes.mobileNavColor
                ? navAttributes.mobileNavColor
                : "#000",
              marginTop: "4px",
              cursor: "pointer",
              zIndex: 999999,
              fontSize: navAttributes.hamburgerSize,
            }}
          />
        ) : (
          <Close
            onClick={() => setMobileMenuOpen(false)}
            style={{
              color: navAttributes.mobileNavColor
                ? navAttributes.mobileNavColor
                : "#fff",
              marginTop: "4px",
              cursor: "pointer",
            }}
          />
        )}
      </div>
    </>
  );
}
