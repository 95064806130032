import { ErrorBoundary } from "react-error-boundary";
// import { useNavigate } from "react-router-dom";
import { logErrorToBackend } from "./utilities/helpers";
import React from "react";

export default function ErrorFallbackComponent(props: any) {
  const ErrorFallbackView = ({ error, resetErrorBoundary }: any) => {
    // const navigate = useNavigate();
    return (
      <div
        className="ErrorFallbackComponent"
        role="alert"
        style={{
          textAlign: "center",
          backgroundColor: "#ccc",
          color: "black",
        }}
      >
        <b>Some problem here ...</b>
        {/* <pre>{error.message}</pre> */}
        {/* {props.showHomeButton && <button type="button" onClick={() => {resetErrorBoundary(); navigate("/")}}>Home</button>} */}
      </div>
    );
  };
  const logError = (error: Error, info?: { componentStack?: any }) => {
    const data = {
      name: error.name,
      message: error.message,
      stack: error.stack,
      componentStack: info!.componentStack,
      href: window.location.href,
    };
    logErrorToBackend(data);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackView} onError={logError}>
      {props.children}
    </ErrorBoundary>
  );
}
