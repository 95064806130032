import { useAppSelector } from "../../../store/hooks";
import { useShoppingCart } from "../context/ShoppingCartContext";
import usePageLanguage from "../hooks/usePageLanguage";
import { CartItemRow } from "../ShoppingCart/CartItemRow";
import { pf } from "../utilities/helpers";

import React, { useEffect, useRef, useState } from "react";

const languageTranslations = {
  et: {
    products: "TOOTED",
    total: "KOKKU",
    additionl_information: "Lisainfo",
    sum: "Summa",
    discount: "Kohandus",
    delivery: "Tarne",
  },
  en: {
    products: "PRODUCTS",
    total: "TOTAL",
    sum: "Sum",
    additionl_information: "Additional information",
    discount: "Adjustment",
    delivery: "Delivery",
  },
};

export default function CheckoutProductsPanel({ id }) {
  const {
    cartItems,
    orderDeliveryMethod,
    totalCartSum,
    locationProducts,

    additionalInfo,
    setAdditionalInfo,
  } = useShoppingCart();

  const totalCartDiscount = cartItems.reduce((total, cartItem) => {
    const item = locationProducts.find((i) => i.id === cartItem.id);

    const originalPrice = item?.price_total || 0;

    const discountedPrice = item?.price_with_discounts || 0;
    const discountAmount =
      (originalPrice - Number(discountedPrice)) * cartItem.quantity;

    return total + discountAmount;
  }, 0);

  const deliveryPrice =
    orderDeliveryMethod !== null && orderDeliveryMethod?.price
      ? Number(orderDeliveryMethod.price)
      : 0;

  const [textAreaValue, setTextAreaValue] = useState("");

  const useAutosizeTextArea = (
    textAreaRef: HTMLTextAreaElement | null,
    value: string
  ) => {
    useEffect(() => {
      if (textAreaRef) {
        textAreaRef.style.height = "0px";
        const scrollHeight = textAreaRef.scrollHeight;

        textAreaRef.style.height = scrollHeight + "px";
      }
    }, [textAreaRef, value]);
  };

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, textAreaValue);

  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;

  const locale = usePageLanguage();

  return (
    <div className="panel" id="checkoutProductsPanel">
      <div className="panel-heading">
        <div className="panel-title">
          <span className="badge badge-square badge-primary ">1</span>
          <span className="panel-title-text">
            {languageTranslations[locale]?.products}
          </span>
        </div>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="col-xs-12 padding-top-20">
            <div
              className="table"
              style={{ fontSize: "14px", width: "100%", paddingTop: "5px" }}
            >
              <div>
                {cartItems.map((item) => (
                  <CartItemRow key={item.id} {...item} />
                ))}
              </div>
            </div>
          </div>
          {component.attributes.additional_info === "visible" && (
            <div>
              {/* TextArea for order information */}
              <div
                className="col-xs-12"
                style={{ marginBottom: "25px", marginTop: "20px" }}
              >
                <textarea
                  id="note_textarea"
                  className="form-control"
                  ref={textAreaRef}
                  style={{
                    fontWeight: "400",
                    lineHeight: "1.5",
                    padding: "0px 0px",
                    margin: "0px",
                    fontSize: "15px",
                    width: "100%",
                    color: "black",
                    borderWidth: "0px 0px 1px 0px",
                    borderRadius: "0px",
                    borderColor: "gray",
                    outline: "none",

                    boxShadow: "none",
                  }}
                  value={additionalInfo}
                  onChange={(value) => {
                    if (value.target.value) {
                      setAdditionalInfo(value.target.value);
                      setTextAreaValue(value.target.value);
                    }
                  }}
                  placeholder={
                    languageTranslations[locale]?.additionl_information
                  }
                ></textarea>
              </div>
            </div>
          )}

          {orderDeliveryMethod?.id || totalCartDiscount ? (
            <div className="col-xs-12 padding-top-20">
              <div style={{ fontWeight: "400", fontSize: "15px" }}>
                {languageTranslations[locale]?.sum}
                <span className="pull-right">
                  <span className="cartTotal">
                    {pf(
                      cartItems.reduce((total, cartItem) => {
                        const item = locationProducts.find(
                          (i) => i.id === cartItem.id
                        );
                        return (
                          total + (item?.price_total || 0) * cartItem.quantity
                        );
                      }, 0)
                    )}
                  </span>
                  &euro;
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          {totalCartDiscount ? (
            <div className="col-xs-12 ">
              <div
                className="discount-price-color"
                style={{ fontWeight: "400", fontSize: "15px" }}
              >
                {languageTranslations[locale]?.discount}
                <span className="pull-right">
                  <span className="cartTotal">
                    {pf(Number(-totalCartDiscount))}
                  </span>{" "}
                  &euro;
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          {orderDeliveryMethod?.id && (
            <div className="col-xs-12 ">
              <div style={{ fontWeight: "400", fontSize: "15px" }}>
                {languageTranslations[locale]?.delivery}
                <span className="pull-right">
                  <span className="cartTotal">
                    {orderDeliveryMethod ? pf(deliveryPrice) : pf(0)}
                  </span>{" "}
                  &euro;
                </span>
              </div>
            </div>
          )}
          <div className="col-xs-12 ">
            <div style={{ fontWeight: "400", fontSize: "18px" }}>
              {languageTranslations[locale]?.total}
              <span className="pull-right">
                <span className="cartTotal">
                  {pf(
                    cartItems.reduce((total, cartItem) => {
                      const item = locationProducts.find(
                        (i) => i.id === cartItem.id
                      );
                      return (
                        total + (item?.price_total || 0) * cartItem.quantity
                      );
                    }, 0) -
                      totalCartDiscount +
                      (orderDeliveryMethod ? deliveryPrice : 0)
                  )}

                  {/* {pf(
                    totalCartSum -
                      totalCartDiscount +
                      (orderDeliveryMethod ? deliveryPrice : 0)
                  )} */}
                </span>{" "}
                &euro;
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
