import { AllComponents } from "./interfaces/base";
import { getPageLocaleUrl } from "./utilities/helpers";
import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import BackgroundVideoComponent from "./BackgroundVideoComponent";
import useLazyLoad from "./utilities/useLazyLoad";
import { Link, useNavigate } from "react-router-dom";
import MegaMenuComponent from "./MegaMenuComponent";
import React from "react";

interface Props {
  id: number;
  attributes: AllComponents["attributes"] & any; //Fix this
  childElements: AllComponents[];
  childWebpageComponentRelations: any;
  editor: JSX.Element;
}

const Section = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function SectionComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
  editor,
}: Props) {
  const sectionRefElement = useRef<HTMLDivElement>(null);
  const isBuilder = document.getElementById("BuilderContent");
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;
  const [backgroundStyles, setBackgroundStyles] =
    useState<React.CSSProperties>();

  const colors = useAppSelector((state) => state.builder.colors);
  const languages = useAppSelector(
    (state) => state.builder.languageSettings.activeLanguages
  );
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);
  const navigate = useNavigate();

  const [backgroundImage, setBackgroundImage] = useState(
    component &&
      component.attributes &&
      component.attributes.styles.backgroundImage
      ? component.attributes.styles.backgroundImage
      : ""
  );

  // if (classes && isBuilder) {
  //   console.log("classes", classes);

  //   if (classes.includes("d-none")) {
  //     classes = classes.replace("d-none", "b-d-none");
  //   } else if (classes.includes("d-sm-none")) {
  //     classes = classes.replace("d-sm-none", "b-d-sm-none");
  //   } else if (classes.includes("d-md-none")) {
  //     classes = classes.replace("d-md-none", "b-d-md-none");
  //   } else if (classes.includes("d-lg-none")) {
  //     classes = classes.replace("d-lg-none", "b-d-lg-none");
  //   } else if (classes.includes("d-xl-none")) {
  //     classes = classes.replace("d-xl-none", "b-d-xl-none");
  //   }

  //   console.log("updated calsses", classes);
  // }

  const [classes, setClasses] = useState(
    component && component.attributes && component.attributes.classes
  );

  useEffect(() => {
    if (!component.attributes.classes) return;

    let componentClasses = component.attributes.classes;

    if (componentClasses && !isBuilder) {
      const classReplacements = [
        {
          original: "d-none",
          block: "d-sm-block",
        },
        {
          original: "d-sm-none",
          block: "d-md-block",
        },
        {
          original: "d-md-none",
          block: "d-lg-block",
        },
        {
          original: "d-lg-none",
          block: "d-xl-block",
        },
        {
          original: "d-xxl-none d-xl-none",
          block: "d-xxl-block ",
        },
      ];

      classReplacements.forEach(({ original, block }) => {
        componentClasses += ` ${block}`;
      });

      setClasses(componentClasses);
    }

    if (componentClasses && isBuilder) {
      // Add display: block!important, to sectionElementRef

      const sectionElementRef = sectionRefElement.current;

      if (sectionElementRef) {
        sectionElementRef.style.display = "block!important";
      }

      const classReplacements = [
        {
          original: "d-none",
          block: "d-sm-block",
        },
        {
          original: "d-sm-none",
          block: "d-md-block",
        },
        {
          original: "d-md-none",
          block: "d-lg-block",
        },
        {
          original: "d-lg-none",
          block: "d-xl-block",
        },
        {
          original: "d-xl-none d-xxl-none",
          block: "d-xxl-block ",
        },

        {
          original: "d-xxl-none",
          block: "d-xxl-block ",
        },
      ];

      classReplacements.forEach(({ original, block }) => {
        if (componentClasses.includes(original)) {
          componentClasses = componentClasses.replace(
            original,
            `b-${original}`
          );
        }

        componentClasses += ` ${block}`;
      });

      setClasses(componentClasses);
    }
  }, [
    component?.attributes?.classes,
    isBuilder,
    sectionRefElement.current,
    colors,
  ]);

  const handleMegaMenuOpen = (boolean) => {
    if (component.attributes.link?.type === "MegaMenu") {
      setMegaMenuOpen(boolean);
    }
  };

  const pages = useAppSelector((state) => state.builder.pages);

  const loaded = useLazyLoad(sectionRefElement, backgroundImage);

  useEffect(() => {
    if (!component.attributes) return;

    const backgroundColor = component.attributes.color_id
      ? colors?.find((x: any) => x.id === component.attributes.color_id)?.color
      : component.attributes.styles.backgroundColor
      ? component.attributes.styles.backgroundColor
      : "";

    const backgroundColorHover = component.attributes.hover_color_id
      ? colors?.find((x: any) => x.id === component.attributes.hover_color_id)
          ?.color
      : component.attributes.styles[":hover"] &&
        component.attributes.styles[":hover"].backgroundColor
      ? component.attributes.styles[":hover"].backgroundColor
      : "";

    const backgroundObject = {
      backgroundColor: `${backgroundColor}!important`,
      ":hover": {
        backgroundColor: `${backgroundColorHover} !important`,
      },
    };

    setBackgroundStyles(backgroundObject);
  }, [
    component?.attributes.color_id,
    component?.attributes.hover_color_id,
    component?.attributes.styles.backgroundColor,
    component?.attributes.styles[":hover"]?.backgroundColor,
    colors,
  ]);

  useEffect(() => {
    if (component.attributes.styles.backgroundImage) {
      setBackgroundImage(component.attributes.styles.backgroundImage);
    }
  }, [component?.attributes.styles.backgroundImage]);

  if (attributes && attributes.classes == "row") {
    let style: any = {};
    if (attributes.content_width_size) {
      style = {
        maxWidth: attributes.content_width_size + attributes.content_width_unit,
      };
    }

    return (
      <>
        <div
          ref={sectionRefElement}
          className="container g-0"
          style={style}
          id={
            attributes.anchor_id
              ? attributes.anchor_id.includes("#")
                ? attributes.anchor_id.split("#")[1]
                : attributes.anchor_id
              : ""
          }
        >
          <Section
            id={
              id
                ? id.toString()
                : "" + attributes.anchor_id
                ? attributes.anchor_id
                : ""
            }
            className={
              component.attributes &&
              component.attributes.classes +
                ` g-0 builder-component relative builder-component__section ${id}-section
              ` +
                (editorId === id ? " builder-component--active" : "")
            }
            data-style={
              attributes && attributes.styles
                ? {
                    ...attributes.styles,

                    ...backgroundStyles,
                    backgroundColor: backgroundStyles?.backgroundColor,

                    backgroundImage: "none",
                  }
                : {}
            }
            data-component-type="section"
            style={{
              ...attributes.styles,
              zIndex: "inherit",
              position: "relative",
              backgroundImage: !isBuilder
                ? loaded
                  ? loaded.map((src) => `url("${src}")`).join(", ")
                  : "none"
                : component.attributes.styles.backgroundImage,
            }}
          >
            <BackgroundVideoComponent id={id} />

            {attributes && attributes.text}
            <RenderChildren
              key={id + "children"}
              childWebpageComponentRelations={childWebpageComponentRelations}
            />
          </Section>
        </div>
      </>
    );
  }
  if (attributes && attributes.classes == "container-fluid") {
    let style: any = {};
    if (attributes.content_width_size) {
      style = {
        maxWidth: attributes.content_width_size + attributes.content_width_unit,
      };
    } else {
      style = {
        maxWidth: "100%",
      };
    }

    return (
      <Section
        ref={sectionRefElement}
        id={id ? id.toString() : ""}
        className={
          " g-0 builder-component relative builder-component__section" +
          (editorId === id ? " builder-component--active" : "")
        }
        data-style={
          attributes && attributes.styles
            ? {
                ...attributes.styles,

                ...backgroundStyles,
                backgroundColor: backgroundStyles?.backgroundColor,
                backgroundImage: "none",
              }
            : {}
        }
        style={{
          position: "relative",
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : component.attributes.styles.backgroundImage,
          zIndex: "inherit",
        }}
        data-component-type="section"
      >
        <BackgroundVideoComponent id={id} />
        {attributes && attributes.text}
        <div className="container g-0" style={style}>
          <RenderChildren
            key={id + "children"}
            childWebpageComponentRelations={childWebpageComponentRelations}
          />
        </div>
      </Section>
    );
  }

  function generateLink(link: any) {
    if (!attributes) return;

    let href;

    if (link?.type === "Page" && link?.pageId) {
      const page = pages.list.find((x) => x.id === link?.pageId);

      if (page) {
        href = getPageLocaleUrl(page, languages);
      }
    } else if (
      attributes.link?.type === "Page" &&
      !attributes.link?.pageId &&
      attributes.link?.url
    ) {
      const page = pages.list.find((x) => x.url === attributes.link?.url);

      if (page) {
        href = getPageLocaleUrl(page, languages);
      }
    } else if (link?.type === "Web_out") {
      if (!link.url.includes("http")) {
        href = `https://${link?.url}`;
      } else {
        href = link.url;
      }
    } else if (link.type === "Web") {
      if (link.url.startsWith("/")) {
        href = link?.url;
      } else {
        href = "/" + link?.url;
      }
    } else if (link.type === "None") {
      href = "";
    } else if (link.type === "Anchor") {
      const anchorLink = link.url.startsWith("#") ? link.url : `#${link.url}`;
      href = anchorLink;
    } else if (link.type === "Phone") {
      if (link.url.startsWith("tel:")) {
        href = link.url;
      } else {
        href = `tel:${link.url}`;
      }
    } else if (link.type === "Email") {
      if (link.url.startsWith("mailto:")) {
        href = link.url;
      } else {
        href = `mailto:${link.url}`;
      }
    }

    return link.type === "Page" ? (
      <Link
        className="button-link"
        key={link.text + link.type + href}
        to={`${href}`} // Avoid redirecting when editing
        target={link.type !== "None" ? link.target : "sefsefes"}
        contentEditable={editorId == id}
        dangerouslySetInnerHTML={{ __html: link?.text }}
      ></Link>
    ) : (
      <a
        className="button-link"
        key={link.text + link.type + href}
        href={href}
        target={
          link.type !== "None" && link.type !== "" ? link.target : "_self"
        }
        contentEditable={editorId == id}
        dangerouslySetInnerHTML={{ __html: link?.text }}
        onClick={() => {}}
        style={{
          zIndex: 8,
        }}
      ></a>
    );
  }

  let href;

  if (attributes.link?.type === "Page" && attributes.link?.pageId) {
    const page = pages.list.find((x) => x.id === attributes.link?.pageId);

    if (page) {
      href = getPageLocaleUrl(page, languages);
    }
  } else if (
    attributes.link?.type === "Page" &&
    !attributes.link?.pageId &&
    attributes.link?.url
  ) {
    const page = pages.list.find((x) => x.url === attributes.link?.url);

    if (page) {
      href = getPageLocaleUrl(page, languages);
    }
  } else if (attributes.link?.type === "Web_out") {
    if (!attributes.link.url.includes("http")) {
      href = `https://${attributes.link?.url}`;
    } else {
      href = attributes.link.url;
    }
  } else if (attributes.link?.type === "Web") {
    if (attributes.link?.url.startsWith("/")) {
      href = attributes.link?.url;
    } else {
      href = "/" + attributes.link?.url;
    }
  } else if (attributes.link?.type === "None") {
    href = "";
  } else if (attributes.link?.type === "Anchor") {
    const anchorLink = attributes.link.url.startsWith("#")
      ? attributes.link.url
      : `#${attributes.link.url}`;
    href = anchorLink;
  } else if (attributes.link?.type === "Phone") {
    if (attributes.link.url.startsWith("tel:")) {
      href = attributes.link.url;
    } else {
      href = `tel:${attributes.link.url}`;
    }
  } else if (attributes.link?.type === "Email") {
    if (attributes.link.url.startsWith("mailto:")) {
      href = attributes.link.url;
    } else {
      href = `mailto:${attributes.link.url}`;
    }
  } else {
    href = "";
  }

  return (
    <Section
      ref={sectionRefElement}
      id={id ? id.toString() : ""}
      data-style={
        attributes && attributes.styles
          ? {
              ...attributes.styles,
              ...backgroundStyles,
              backgroundColor: backgroundStyles?.backgroundColor,
              backgroundImage: "none",
            }
          : {}
      }
      className={
        classes +
        " g-0  builder-component COLUMN relative builder-component__section" +
        (editorId === id ? " builder-component--active" : "")
      }
      data-component-type="section"
      style={{
        backgroundImage: !isBuilder
          ? loaded
            ? loaded.map((src) => `url("${src}")`).join(", ")
            : "none"
          : component.attributes.styles.backgroundImage,

        cursor:
          href !== "" && href !== undefined && href.trim() !== ""
            ? "pointer"
            : "default",

        display: "block!important",
      }}
      data-href={href}
      onClick={() => {
        if (attributes.link?.type === "MegaMenu") {
          handleMegaMenuOpen(!megaMenuOpen);
        }

        if (isBuilder) {
          return;
        }

        if (href !== "" && href !== undefined && href.trim() !== "") {
          if (attributes.link?.target === "_blank") {
            window.open(href, "_blank");
            return;
          } else if (attributes.link?.type !== "Web_out") {
            navigate(href);
            window.scrollTo(0, 0);
          } else if (attributes.link?.type === "Web_out") {
            window.location.href = href;
          }
        }
      }}
      onMouseEnter={(e) => {
        e.preventDefault();
        handleMegaMenuOpen(true);

        // setMegaMenuOpen(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();

        handleMegaMenuOpen(false);

        // setMegaMenuOpen(false);
      }}
    >
      {attributes &&
        attributes.link &&
        attributes.link.type === "MegaMenu" &&
        attributes.link.template_id !== null && (
          <>
            <MegaMenuComponent
              id={attributes.link.template_id}
              parentId={id}
              menuOpen={megaMenuOpen}
              setMegaMenuOpen={setMegaMenuOpen}
              leftPosition={attributes.link?.left_position}
              topPosition={attributes.link?.top_position}
              activateType={attributes.link?.activate_type}
            />
          </>
        )}

      {attributes &&
        attributes.link &&
        attributes.link.type === "Dropdown" &&
        attributes.links &&
        attributes.links.length > 0 && (
          <>
            <div className="col-subnav">
              {attributes.links && attributes.links.length > 0 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {attributes.links.map((item, i) => generateLink(item))}
                </div>
              )}
            </div>
          </>
        )}

      <BackgroundVideoComponent id={id} />

      {attributes && attributes.text}
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />
    </Section>
  );
}
