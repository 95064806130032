import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";

function usePageLanguage() {
  const pageId = useAppSelector((state) => state.builder.pages.active);
  const languages = useAppSelector((state) => state.builder.languageSettings);

  const currentPage = useAppSelector((state) => state.builder.pages.list).find(
    (x) => x.id === pageId
  );
  var locale = "et";
  if (currentPage) {
    const activeLang = languages.activeLanguages?.find(
      (x) => x.id === currentPage.website_language_id
    );
    if (activeLang) {
      locale = activeLang.language.shortname;
    }
  }
  const [pageLanguage, setPageLanguage] = useState(locale);

  useEffect(() => {
    if (currentPage) {
      const activeLang = languages.activeLanguages?.find(
        (x) => x.id === currentPage.website_language_id
      );
      if (activeLang && activeLang.language.shortname != pageLanguage) {
        setPageLanguage(activeLang.language.shortname);
      }
    }
  }, [pageId, languages]);

  return pageLanguage;
}

export default usePageLanguage;
