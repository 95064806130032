import React from "react";

type PropType = {
  slide: any;
  selected: boolean;
  index: number;
  onClick: () => void;
  isFullscreen?: boolean;
};

export const ThumbFullscreen: React.FC<PropType> = (props) => {
  const { slide, selected, index, onClick, isFullscreen } = props;

  return (
    <div
      className={
        "embla-thumbs__slide".concat(
          selected ? " embla-thumbs__slide--selected" : ""
        ) + " embla-thumbs__slice__fullscreen"
      }
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }}
        type="button"
        className="embla-thumbs__slide__number"
        style={{
          backgroundImage: slide.url ? `url(${slide.url})` : slide.src,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
          width: "100%",
          border: "none",
          outline: "none",
          cursor: "pointer",
        }}
      ></button>
    </div>
  );
};
