import styled from "@emotion/styled";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import useEmblaCarousel from "embla-carousel-react";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Thumb } from "./ProductDetail/ProductImagesComponentThumbs";

import "./MediaSliderComponent.css";
import AutoScroll from "embla-carousel-auto-scroll";
import AutoPlay from "embla-carousel-autoplay";
import ProductImagesComponentFullscreen from "./ProductDetail/ProductImagesComponentFullscreen";
import useLazyLoad from "./utilities/useLazyLoad";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function MediaSliderComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;

  const mediaSliderRef = useRef<HTMLDivElement>(null);
  const [backgroundStyles, setBackgroundStyles] =
    useState<React.CSSProperties>();

  const colors = useAppSelector((state) => state.builder.colors);

  const loaded = useLazyLoad(
    mediaSliderRef,
    component.attributes.styles.backgroundImage
  );

  const [slides, setSlides] = useState<any[]>([]);

  const [loop, setLoop] = useState(
    component.attributes.loop ? component.attributes.loop : true
  );
  const [slideCount, setSlidecount] = useState(
    component.attributes.styles.backgroundImage
      ? component.attributes.styles.backgroundImage.split(",").length
      : 0
  );

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [imageHeight, setImageHeight] = useState(
    component.attributes.imageHeightNumber &&
      component.attributes.imageHeightUnit
      ? `${component.attributes.imageHeightNumber}${component.attributes.imageHeightUnit}`
      : "500px"
  );

  const [imageWidth, setImageWidth] = useState(
    component.attributes.imageWidthNumber && component.attributes.imageWidthUnit
      ? `${component.attributes.imageWidthNumber}${component.attributes.imageWidthUnit}`
      : "500px"
  );

  const [thumbnailAlign, setThumbnailAlign] = useState(
    component.attributes.thumbnailAlign
      ? component.attributes.thumbnailAlign
      : "start"
  );

  const [maintainAspectRatio, setMaintainAspectRatio] = useState(
    component.attributes.maintainAspectRatio ? true : false
  );

  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });

  const [imageRatioHeight, setImageRatioHeight] = useState<number>();

  const [isFullScreen, setIsFullScreen] = useState(false);

  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(
    {
      loop: component.attributes.loop ? component.attributes.loop : false,
      align: component.attributes.slideAlign
        ? component.attributes.slideAlign
        : "center",
    },

    // @ts-ignore
    component.attributes &&
      component.attributes.autoplay &&
      component.attributes.autoplay !== "none"
      ? [
          component.attributes.autoplay == "autoplay"
            ? AutoPlay({
                playOnInit: true,
                delay: component.attributes.autoplayInterval
                  ? component.attributes.autoplayInterval
                  : 3000,
                stopOnInteraction: false,
              })
            : AutoScroll({
                speed: component.attributes.autoscrollSpeed
                  ? component.attributes.autoscrollSpeed
                  : 1,
                stopOnInteraction: false,
              }),
        ]
      : []
  );

  // let slides: any[] = [];

  useEffect(() => {
    if (component.attributes.styles.backgroundImage) {
      const slideCount =
        component.attributes.styles.backgroundImage.split(",").length;

      let slideTemplate: any[] = [];

      for (let i = 0; i < slideCount; i++) {
        // setSlides([]);

        // setSlides((slides) => [
        //   ...slides,
        //   {
        //     src: component.attributes.styles?.backgroundImage.split(",")[i],
        //     objectFit: component.attributes.styles?.objectFit.split(",")[i],
        //     id: i,
        //   },
        // ]);
        slideTemplate.push({
          src: component.attributes.styles?.backgroundImage.split(",")[i],

          id: i,
        });
      }
      setSlides(slideTemplate);
    }
  }, [component.attributes]);

  const handleFullScreen = () => {
    if (
      component.attributes &&
      (component.attributes.fullscreen === false ||
        component.attributes.fullscreen === 0)
    ) {
      setIsFullScreen(false);
    } else if (
      component.attributes.fullscreen === true ||
      component.attributes.fullscreen === 1
    ) {
      setIsFullScreen(!isFullScreen);
    }
  };

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  const logSlidesInView = useCallback((emblaApi: any) => {
    console.log(emblaApi.slidesInView(true), "slides in view");
  }, []);

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect);
    emblaMainApi.on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);

  useEffect(() => {
    if (emblaMainApi) {
      emblaMainApi.on("slidesInView", logSlidesInView);
    }
  }, [emblaMainApi, logSlidesInView]);

  const scrollPrev = useCallback(() => {
    if (emblaMainApi) {
      emblaMainApi.scrollPrev();
      setSelectedIndex(emblaMainApi.selectedScrollSnap());
    }
  }, [emblaMainApi]);

  const scrollNext = useCallback(() => {
    if (emblaMainApi) {
      emblaMainApi.scrollNext();
      setSelectedIndex(emblaMainApi.selectedScrollSnap());
    }
  }, [emblaMainApi]);

  useEffect(() => {
    setSlidecount(
      component.attributes.styles.backgroundImage
        ? component.attributes.styles.backgroundImage.split(",").length
        : 0
    );

    setImageHeight(
      component.attributes.imageHeightNumber &&
        component.attributes.imageHeightUnit
        ? `${component.attributes.imageHeightNumber}${component.attributes.imageHeightUnit}`
        : "500px"
    );

    setImageWidth(
      component.attributes.imageWidthNumber &&
        component.attributes.imageWidthUnit
        ? `${component.attributes.imageWidthNumber}${component.attributes.imageWidthUnit}`
        : "500px"
    );

    setThumbnailAlign(
      component.attributes.thumbnailAlign
        ? component.attributes.thumbnailAlign
        : "start"
    );

    setLoop(component.attributes.loop ? component.attributes.loop : true);

    setMaintainAspectRatio(
      component.attributes.maintainAspectRatio
        ? component.attributes.maintainAspectRatio
        : false
    );
  }, [component]);

  useEffect(() => {
    const adjustHeight = () => {
      if (!component.attributes.maintainAspectRatio) return;

      if (!mediaSliderRef.current) return;

      const aspectRatio =
        mediaSliderRef.current.offsetHeight /
        mediaSliderRef.current.offsetWidth;

      const backgroundImages = component.attributes.styles.backgroundImage;

      if (!backgroundImages) return;

      let urlMatch;

      if ((backgroundImages as string).includes("url(")) {
        // If multiple URLs, split the string and remove empty strings
        urlMatch = (backgroundImages as string)
          .split('url("')
          .map((url) => url.replace('")', ""))
          .filter(Boolean);
      }

      let selectedUrl;

      if (urlMatch[selectedIndex].endsWith(",")) {
        selectedUrl = urlMatch[selectedIndex].slice(0, -1);
      } else {
        selectedUrl = urlMatch[selectedIndex];
      }

      if (!selectedUrl) return;

      const image = document.createElement("img");
      image.src = selectedUrl;

      image.onload = () => {
        const imageAspectRatio = image.height / image.width;

        if (aspectRatio > imageAspectRatio && mediaSliderRef.current) {
          if (
            component.attributes.thumbnails === true ||
            component.attributes.thumbnails === 1
          ) {
            setImageRatioHeight(
              mediaSliderRef.current.offsetWidth * imageAspectRatio
            );
            mediaSliderRef.current.style.height = `${
              mediaSliderRef.current.offsetWidth * imageAspectRatio +
              (component.attributes.thumbnailHeightNumber
                ? component.attributes.thumbnailHeightNumber
                : 120) +
              5
            }px`;
          } else {
            setImageRatioHeight(
              mediaSliderRef.current.offsetWidth * imageAspectRatio
            );
            mediaSliderRef.current.style.height = `${
              mediaSliderRef.current.offsetWidth * imageAspectRatio
            }px`;
          }
        } else if (aspectRatio < imageAspectRatio && mediaSliderRef.current) {
          if (
            component.attributes.thumbnails === true ||
            component.attributes.thumbnails === 1
          ) {
            setImageRatioHeight(
              mediaSliderRef.current.offsetWidth * imageAspectRatio
            );

            mediaSliderRef.current.style.height = `${
              mediaSliderRef.current.offsetWidth * imageAspectRatio +
              (component.attributes.thumbnailHeightNumber
                ? component.attributes.thumbnailHeightNumber
                : 120) +
              5
            }px`;
          } else {
            setImageRatioHeight(
              mediaSliderRef.current.offsetWidth * imageAspectRatio
            );

            mediaSliderRef.current.style.height = `${
              mediaSliderRef.current.offsetWidth * imageAspectRatio
            }px`;
          }
        }
      };
    };

    adjustHeight();

    const handleResize = () => {
      adjustHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [
    maintainAspectRatio,
    selectedIndex,

    imageRatioHeight,
    mediaSliderRef,
    mediaSliderRef.current,
    component,
    component.attributes.styles.backgroundImage,
    component.attributes.thumbnails,
  ]);

  useEffect(() => {
    const backgroundColor = component.attributes?.color_id
      ? colors?.find((x: any) => x.id === component.attributes?.color_id)?.color
      : component.attributes?.styles.backgroundColor
      ? component.attributes?.styles.backgroundColor
      : "";

    const backgroundColorHover = component.attributes?.hover_color_id
      ? colors?.find((x: any) => x.id === component.attributes?.hover_color_id)
          ?.color
      : component.attributes?.styles[":hover"] &&
        component.attributes?.styles[":hover"].backgroundColor
      ? component.attributes?.styles[":hover"].backgroundColor
      : "";

    const backgroundObject = {
      backgroundColor: `${backgroundColor ? backgroundColor : "#fff"} ${
        backgroundColor ? "!important" : ""
      }`,
      ":hover": {
        backgroundColor: `${backgroundColorHover} ${
          backgroundColor ? "!important" : ""
        }`,
      },
    };

    setBackgroundStyles(backgroundObject);
  }, [
    component.attributes,
    component.attributes?.color_id,
    component.attributes?.hover_color_id,
    component.attributes?.styles.backgroundColor,
    component.attributes?.styles[":hover"]?.backgroundColor,
    colors,
  ]);

  return (
    <Div
      ref={mediaSliderRef}
      id={id.toString()}
      data-component-type="media-slider"
      className={
        (attributes.classes ?? "") +
        " builder-component builder-component__media_slider" +
        (editorId === id ? " builder-component--active" : "")
      }
      style={{
        ...attributes.styles,

        height:
          component.attributes.maintainAspectRatio === true ||
          component.attributes.maintainAspectRatio === 1
            ? "auto"
            : imageHeight,

        maxWidth: imageWidth,

        backgroundImage: "none",
      }}
      data-style={{
        ...backgroundStyles,
        backgroundColor: backgroundStyles?.backgroundColor
          ? backgroundStyles?.backgroundColor
          : "none",
      }}
    >
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />
      <>
        <div className="media-slider">
          <div
            className="embla__viewport"
            ref={emblaMainRef}
            style={{ position: "relative" }}
          >
            <div className="embla__container">
              {loaded &&
                mediaSliderRef.current &&
                loaded.length > 0 &&
                [...Array(slideCount)].map((_, index) => {
                  let backgroundUrl = "";
                  let backgroundOrigin = "";
                  let backgroundPosition = "";
                  let backgroundSize = "";
                  let backgroundAttachment = "";
                  let backgroundRepeat = "";

                  if (component.attributes.styles.backgroundImage) {
                    backgroundUrl =
                      component.attributes.styles?.backgroundImage?.split(",")[
                        index
                      ];

                    backgroundOrigin =
                      component.attributes.styles?.backgroundOrigin.split(",")[
                        index
                      ];

                    backgroundPosition =
                      component.attributes.styles?.backgroundPosition.split(
                        ","
                      )[index];
                    backgroundSize =
                      component.attributes.styles?.backgroundSize.split(",")[
                        index
                      ];
                    backgroundAttachment =
                      component.attributes.styles?.backgroundAttachment.split(
                        ","
                      )[index];

                    backgroundRepeat =
                      component.attributes.styles.backgroundRepeat.split(",")[
                        index
                      ];
                  }

                  let base;

                  if (
                    component.attributes.maintainAspectRatio === true ||
                    component.attributes.maintainAspectRatio === 1
                  ) {
                    base = imageRatioHeight;
                  } else {
                    base = component.attributes.imageHeightNumber
                      ? component.attributes.imageHeightNumber
                      : 500;
                  }

                  let height;

                  if (component.attributes.thumbnails) {
                    if (
                      component.attributes.maintainAspectRatio === true ||
                      component.attributes.maintainAspectRatio === 1
                    ) {
                      height = base + "px";
                    } else {
                      height =
                        base -
                        (component.attributes.thumbnailHeightNumber
                          ? component.attributes.thumbnailHeightNumber
                          : 120) -
                        5 +
                        "px";
                    }
                  } else {
                    if (
                      component.attributes.maintainAspectRatio === true ||
                      component.attributes.maintainAspectRatio === 1
                    ) {
                      height = base + "px";
                    } else {
                      height = component.attributes.imageHeightNumber
                        ? component.attributes.imageHeightNumber
                        : 500 + component.attributes.imageHeightUnit
                        ? component.attributes.imageHeightUnit
                        : "500px";
                    }
                  }

                  // const thumbnailHeightValue = component.attributes.thumbnailHeightNumber ? component.attributes.imageHeightNumber ? component.attributes.imageHeightNumber : 500  component.attributes.thumbnailHeightNumber

                  return (
                    <div
                      className="embla__slide"
                      style={{
                        width: "100%",
                        flex: `0 0 ${
                          component.attributes.slideWidth
                            ? component.attributes.slideWidth
                            : 100
                        }%`,
                      }}
                      key={index}
                      onClick={handleFullScreen}
                    >
                      <div className="embla__slide__number">
                        <div
                          className=""
                          style={{
                            width: "100%",
                            backgroundImage: backgroundUrl,
                            backgroundOrigin: backgroundOrigin,
                            backgroundPosition: backgroundPosition,
                            backgroundSize: backgroundSize,
                            backgroundRepeat: backgroundRepeat,
                            height: height,
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>

            {slides.length > 1}

            <button
              style={{
                display: slides.length > 1 ? "block" : "none",
              }}
              className="embla__prev previous-carousel-button"
              onClick={scrollPrev}
            >
              <ArrowBackIos style={{ fontSize: "16px", marginLeft: "2px" }} />
            </button>
            <button
              style={{
                display: slides.length > 1 ? "block" : "none",
              }}
              className="embla__next previous-carousel-button"
              onClick={scrollNext}
            >
              <ArrowForwardIos
                style={{ fontSize: "16px", marginRight: "2px" }}
              />
            </button>
          </div>

          <div
            className="embla-thumbs"
            style={{
              display:
                component.attributes && component.attributes.thumbnails
                  ? "block"
                  : "none",
            }}
          >
            <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
              <div
                className="embla-thumbs__container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: thumbnailAlign,
                  alignItems: "center",
                }}
              >
                {slides.map((slide, index) => (
                  <Thumb
                    componentId={id}
                    slide={slide}
                    key={index}
                    onClick={() => {
                      setSelectedIndex(index);
                      onThumbClick(index);
                    }}
                    selected={index === selectedIndex}
                    index={index}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {isFullScreen && (
          <ProductImagesComponentFullscreen
            activeIndex={selectedIndex}
            handleFullScreen={handleFullScreen}
            slides={slides}
          />
        )}
      </>
    </Div>
  );
}
