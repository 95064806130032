import React, { useCallback, useEffect, useRef, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import "./ProductImagesComponent.css";
import { EmblaOptionsType } from "embla-carousel";
import { Thumb } from "./ProductImagesComponentThumbs";
import { ArrowBackIos, Close } from "@mui/icons-material";
import { ArrowForwardIos } from "@mui/icons-material";
import ProductImagesComponentFullscreen from "./ProductImagesComponentFullscreen";
import { useAppSelector } from "../../../store/hooks";
import { ProductDetailComponent } from "../interfaces/base";

export default function ProductImagesComponent({ slides, id }: any) {
  const OPTIONS: EmblaOptionsType = {
    loop: true,
  };

  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as ProductDetailComponent;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({
    loop: true,
  });
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });

  const [isFullScreen, setIsFullScreen] = useState(false);
  const mediaSliderRef = useRef<any>(null);

  const [isFullscreenAvailable, setIsFullscreenAvailable] = useState(
    component.attributes.fullscreen ? component.attributes.fullscreen : true
  );

  const [isThumbnails, setIsThumbnails] = useState(
    component.attributes.thumbnails ? component.attributes.thumbnails : true
  );

  const [maintainAspectRatio, setMaintainAspectRatio] = useState(
    component.attributes.maintainAspectRatio
      ? component.attributes.maintainAspectRatio === true ||
        component.attributes.maintainAspectRatio === 1
        ? true
        : false
      : true
  );

  const [imageHeight, setImageHeight] = useState(
    component.attributes.imageHeightNumber &&
      component.attributes.imageHeightUnit
      ? `${component.attributes.imageHeightNumber}${component.attributes.imageHeightUnit}`
      : "500px"
  );

  const [objectFit, setObjectFit] = useState(
    component.attributes.styles.objectFit
      ? component.attributes.styles.objectFit
      : "contain"
  );

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect);
    emblaMainApi.on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);
  const scrollPrev = useCallback(() => {
    if (emblaMainApi) emblaMainApi.scrollPrev();
  }, [emblaMainApi]);

  const scrollNext = useCallback(() => {
    if (emblaMainApi) emblaMainApi.scrollNext();
  }, [emblaMainApi]);

  const handleFullScreen = () => {
    if (component.attributes && component.attributes.fullscreen === false) {
      setIsFullScreen(false);
    } else {
      setIsFullScreen(!isFullScreen);
    }
  };

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect);
    emblaMainApi.on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);

  useEffect(() => {
    if (component) {
      setIsFullscreenAvailable(
        component.attributes.fullscreen ? component.attributes.fullscreen : true
      );
      setIsThumbnails(
        component.attributes.thumbnails ? component.attributes.thumbnails : true
      );
      setImageHeight(
        component.attributes.imageHeightNumber &&
          component.attributes.imageHeightUnit
          ? `${component.attributes.imageHeightNumber}${component.attributes.imageHeightUnit}`
          : "500px"
      );
      setObjectFit(
        component.attributes.styles.objectFit
          ? component.attributes.styles.objectFit
          : "contain"
      );

      setMaintainAspectRatio(
        component.attributes.maintainAspectRatio
          ? component.attributes.maintainAspectRatio === true ||
            component.attributes.maintainAspectRatio === 1
            ? true
            : false
          : true
      );

      if (
        component.attributes.maintainAspectRatio !== true &&
        component.attributes.maintainAspectRatio !== 1
      ) {
        setImageHeight(
          component.attributes.imageHeightNumber &&
            component.attributes.imageHeightUnit
            ? `${component.attributes.imageHeightNumber}${component.attributes.imageHeightUnit}`
            : "500px"
        );
      }
    }
  }, [component]);

  useEffect(() => {
    const adjustHeight = () => {
      if (!mediaSliderRef.current) return;
      if (maintainAspectRatio === false) return;

      const aspectRatio =
        mediaSliderRef.current.offsetHeight /
        mediaSliderRef.current.offsetWidth;

      if (!slides) return;

      if (!slides[selectedIndex]) return;

      let selectedUrl = slides[selectedIndex].url;

      if (!selectedUrl) return;

      const image = document.createElement("img");
      image.src = selectedUrl;

      image.onload = () => {
        const imageAspectRatio = image.height / image.width;

        if (aspectRatio > imageAspectRatio && mediaSliderRef.current) {
          setImageHeight(
            `${mediaSliderRef.current.offsetWidth * imageAspectRatio}px`
          );
          mediaSliderRef.current.style.height = `${
            mediaSliderRef.current.offsetWidth * imageAspectRatio
          }px`;
        } else if (aspectRatio < imageAspectRatio && mediaSliderRef.current) {
          setImageHeight(
            `${mediaSliderRef.current.offsetWidth * imageAspectRatio}px`
          );
          mediaSliderRef.current.style.height = `${
            mediaSliderRef.current.offsetWidth * imageAspectRatio
          }px`;
        }
      };
    };

    adjustHeight();

    const handleResize = () => {
      adjustHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [
    maintainAspectRatio,
    selectedIndex,
    imageHeight,
    mediaSliderRef,
    mediaSliderRef.current,
    component,
    component.attributes.styles.backgroundImage,
    component.attributes.thumbnails,
  ]);

  if (!slides) return <></>;

  return (
    <div>
      <div className="embla">
        <div ref={mediaSliderRef}>
          <div
            className="embla__viewport"
            ref={emblaMainRef}
            style={{ position: "relative", height: imageHeight }}
          >
            <div className="embla__container">
              {slides.map((slide, index) => (
                <div
                  className="embla__slide"
                  key={index}
                  onClick={handleFullScreen}
                >
                  <div className="embla__slide__number">
                    <img
                      src={slide.url}
                      alt=""
                      width={"100%"}
                      style={{
                        objectFit: objectFit,
                        height:
                          component.attributes.maintainAspectRatio === true ||
                          component.attributes.maintainAspectRatio === 1
                            ? "auto"
                            : imageHeight,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            {slides.length > 1}

            <button
              style={{
                display: slides.length > 1 ? "block" : "none",
              }}
              className="embla__prev previous-carousel-button"
              onClick={scrollPrev}
            >
              <ArrowBackIos style={{ fontSize: "16px" }} />
            </button>
            <button
              style={{
                display: slides.length > 1 ? "block" : "none",
              }}
              className="embla__next previous-carousel-button"
              onClick={scrollNext}
            >
              <ArrowForwardIos style={{ fontSize: "16px" }} />
            </button>
          </div>
        </div>
        <div
          className="embla-thumbs"
          style={{
            display:
              component.attributes && component.attributes.thumbnails
                ? "block"
                : "none",
          }}
        >
          <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
            <div className="embla-thumbs__container">
              {slides.map((slide, index) => (
                <Thumb
                  componentId={id}
                  slide={slide}
                  key={index}
                  onClick={() => onThumbClick(index)}
                  selected={index === selectedIndex}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {isFullScreen && (
        <ProductImagesComponentFullscreen
          activeIndex={selectedIndex}
          handleFullScreen={handleFullScreen}
          slides={slides}
        />
      )}
    </div>
  );
}
