import { useAppSelector } from "../../store/hooks";
import { useNavigate, useLocation } from "react-router-dom";

import React from "react";
import { useShoppingCart } from "./context/ShoppingCartContext";
import { getPageLocaleUrl } from "./utilities/helpers";

export default function CheckNotFoundPage() {
  const isBuilder = document.getElementById("BuilderContent");

  const pageId = useAppSelector((state) => state.builder.pages.active);
  const currentPage = useAppSelector((state) => state.builder.pages.list).find(
    (x) => x.id === pageId
  );
  const pages = useAppSelector((state) => state.builder.pages.list);
  const navigate = useNavigate();
  const { locationProducts } = useShoppingCart();
  const location = useLocation();
  const website = useAppSelector((state) => state.builder.website);
  const pageLanguages = useAppSelector(
    (state) => state.builder.languageSettings.activeLanguages
  );

  if (location.pathname.includes("/webstoreApi/")) {
    return <></>;
  }

  // Check if pathname is webstoreApi/submitContactForm and dont redirect
  if (location.pathname === "/webstoreApi/submitContactForm") {
    return <></>;
  }

  if (currentPage && currentPage.page_type === "notFound" && !isBuilder) {
    const currentPathname = location.pathname;
    const currentSearch = location.search;

    const languages = navigator.languages;

    let firstLanguage = languages[0];

    if (firstLanguage.includes("-")) {
      firstLanguage = firstLanguage.split("-")[0];
    }

    if (currentPathname === "/" || currentPathname === "") {
      const activeLanguages = pageLanguages.filter(
        (x) => x.visitor_state === 1
      );

      const homepages = pages
        .filter((page) => page.page_type === "frontPage")
        .filter((page) => {
          const pagesupDate = activeLanguages.filter((activeLanguage) => {
            if (activeLanguage.id === page.language.id) {
              return true;
            }
          });

          return pagesupDate.length > 0;
        });

      if (homepages.length === 0) {
        return <></>;
      }

      let selectedHomePage;

      if (website.use_browser_language === 1) {
        const homepageFirstLanguage = homepages.filter(
          (page) => page.language.locale === firstLanguage
        ) as any;

        if (!homepageFirstLanguage && languages.length >= 1) {
          let secondLanguage = languages[1];

          if (secondLanguage.includes("-")) {
            secondLanguage = secondLanguage.split("-")[0];
          }

          selectedHomePage = homepages.find(
            (page) => page.language.locale === secondLanguage
          );
        } else if (homepageFirstLanguage.length) {
          selectedHomePage = homepageFirstLanguage;

          navigate(`/${homepageFirstLanguage[0].language.locale}`);
        }
      } else if (website.use_browser_language === 0 && pageLanguages) {
        selectedHomePage = homepages.find(
          (page) =>
            page.language.locale ===
            pageLanguages.filter((x) => x.visitor_state === 1)[0].language
              .shortname
        );
      }

      if (!selectedHomePage) {
        selectedHomePage = homepages[0];
      }

      navigate(getPageLocaleUrl(selectedHomePage, pageLanguages));
      return <></>;
    } else {
      function jaccardSimilarity(s1, s2) {
        const set1 = new Set(s1.split("/"));
        const set2 = new Set(s2.split("/"));
        const intersection = new Set([...set1].filter((x) => set2.has(x)));
        const union = new Set([...set1, ...set2]);
        return intersection.size / union.size;
      }

      let maxSimilarity = -1;
      let closestPage: any = null;

      pages.forEach((page) => {
        let url = getPageLocaleUrl(page, pageLanguages);

        console.log(url);

        let similarity = jaccardSimilarity(currentPathname, url);
        if (similarity > maxSimilarity) {
          maxSimilarity = similarity;
          closestPage = page;
        }
      });

      console.log(closestPage, "closestPage");

      if (closestPage) {
        if (closestPage.page_type === "product") {
          const pageurl = getPageLocaleUrl(closestPage, pageLanguages).split(
            ":"
          )[0];

          const productId =
            currentPathname.split("/")[currentPathname.split("/").length - 1];

          let locationProduct;

          if (locationProducts) {
            locationProduct = locationProducts.find((product) => {
              let productFound: any = null;

              for (const key in product.translations) {
                if (product.translations[key].product_url.includes(productId)) {
                  productFound = product;
                  break;
                }
              }

              return productFound;
            });
          }

          if (!locationProduct) {
            // Have to return here, otherwise it will throw an error
            return <></>;
          }

          let productUrl = locationProduct ? locationProduct.id : productId;

          const navigateUrl = `${pageurl}${productUrl}`;

          console.log("navigateUrl", navigateUrl + currentSearch);

          navigate(navigateUrl);

          return <></>;
        } else if (closestPage.page_type === "products") {
          const navigateUrl = `${getPageLocaleUrl(closestPage, pageLanguages)}`;
          console.log("navigateUrl", navigateUrl + currentSearch);
          navigate(navigateUrl + currentSearch);

          return <></>;
        } else {
          const navigateUrl = `${getPageLocaleUrl(closestPage, pageLanguages)}`;
          console.log("navigateUrl", navigateUrl + currentSearch);

          navigate(navigateUrl);

          return <></>;
        }
      } else {
        console.log("No match found.");
      }

      return <></>;
    }
  } else {
    return <></>;
  }
}
