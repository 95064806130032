import { useState } from "react";
import ProductsSortDropdownMenu from "./ProductsSortDropdownMenu";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./ProductsSortComponent.css";
import usePageLanguage from "../hooks/usePageLanguage";
import React from "react";
import { useAppSelector } from "../../../store/hooks";

const languageTranslations = {
  et: {
    title: "Sorteeri",
    price_asc: "Odavamad enne",
  },
  en: {
    title: "Sort by",
    price_asc: "Price asc",
  },
};

export default function ProductsSortComponent({
  sortFilter,
  setSortFilter,
  perPage,
  setPerPage,
}: any) {
  const [open, setOpen] = useState<boolean>(false);

  const locale = usePageLanguage();

  const toggleOpen = () => {
    setOpen(!open);
  };

  var title = "";
  title = languageTranslations[locale].title;

  var titleArray: any = [];
  if (sortFilter) {
    switch (sortFilter) {
      case "price_asc":
        titleArray.push(
          locale === "et"
            ? "Odavamad enne"
            : locale === "en"
            ? "Price asc"
            : locale === "ru"
            ? "Russian Translation"
            : "Default Translation"
        );

        break;
      case "price_desc":
        titleArray.push(
          locale === "et"
            ? "Kallimad enne"
            : locale === "en"
            ? "Price desc"
            : locale === "ru"
            ? "Russian Translation"
            : "Default Translation"
        );
        break;
      case "name_desc":
        titleArray.push(
          locale === "et"
            ? "Nimetuse järgi: Z->A"
            : locale === "en"
            ? "By name: Z->A"
            : locale === "ru"
            ? "Russian Translation"
            : "Default Translation"
        );

        break;
      case "name_asc":
        titleArray.push(
          locale === "et"
            ? "Nimetuse järgi: A->Z"
            : locale === "en"
            ? "By name: A->Z"
            : locale === "ru"
            ? "Russian Translation"
            : "Default Translation"
        );
        break;

      default:
        break;
    }
  }
  if (perPage != 30) {
    titleArray.push(
      locale === "et"
        ? perPage + " toodet lehel"
        : locale === "en"
        ? perPage + " products per page"
        : locale === "ru"
        ? "Russian Translation"
        : "Default Translation"
    );
  }

  if (titleArray.length) {
    title = titleArray.join(", ");
  }

  const [isHovered, setIsHovered] = useState(false);

  const typographies = useAppSelector((state) => state.builder.typographies);
  const colors = useAppSelector((state) => state.builder.colors);

  const filteredTypography = typographies.filter((x) => {
    if (
      x.active_components &&
      x.active_components.includes("productsGridSort")
    ) {
      return x;
    } else {
      return null;
    }
  });

  const typographyStyles =
    filteredTypography.length > 0
      ? {
          fontFamily: filteredTypography[0].font_family,
          fontSize:
            filteredTypography[0].font_size +
            filteredTypography[0].font_size_unit,
          fontStyle: filteredTypography[0].font_style,
          fontWeight: filteredTypography[0].font_weight,
          letterSpacing: filteredTypography[0].letter_spacing,
          lineHeight: filteredTypography[0].line_height,
          color: colors.find((x) => x.id === filteredTypography[0].color_id)
            ?.color,

          ...(isHovered && {
            color: colors.find(
              (x) => x.id === filteredTypography[0].link_hover_color_id
            )?.color,
          }),
        }
      : {};

  return (
    <div
      className={
        "ProductsSortComponent dropdown filterDropdown " + (open ? "open" : "")
      }
      style={{
        height: "40px",
      }}
    >
      <button
        onClick={toggleOpen}
        className="btn btn-primary-outline filterDropdownButton shop-controlled-border-radius"
        style={{
          height: "40px",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span id="filterTitle" style={typographyStyles}>
          {title}
        </span>{" "}
        <FilterListIcon
          style={{
            verticalAlign: "initial",
            fontSize: "14px",
            top: "3px",
            position: "relative",
          }}
        />
      </button>
      {open && (
        <ProductsSortDropdownMenu
          setOpen={setOpen}
          sortFilter={sortFilter}
          setSortFilter={setSortFilter}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}
    </div>
  );
}
