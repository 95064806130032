import { useAppSelector } from "../../store/hooks";

import { useLocation, useNavigate } from "react-router-dom";
import { logErrorToBackend } from "./utilities/helpers";
import ContentRenderWebpageComponent from "./ContentRenderWebpageComponent";
import "./Default.css";
import "./ShopTheme.css";
import { useEffect } from "react";
import CookiePolicyComponent from "./CookiePolicyComponent";
import ShoppingCartExpired from "./ShoppingCart/ShoppingCartExpired";
import React from "react";
import { getPageLocaleUrl } from "./utilities/helpers";

declare var defaultComponents: any;

export default function ContentRenderer() {
  const fontUrl = useAppSelector((state) => state.builder.font_url.url);
  const webpageComponents = useAppSelector(
    (state) => state.builder.structure.webpageComponents
  );

  const pages = useAppSelector((state) => state.builder.pages.list);
  const website = useAppSelector((state) => state.builder.website);
  const colors = useAppSelector((state) => state.builder.colors);
  const navigate = useNavigate();
  const pageLanguages = useAppSelector(
    (state) => state.builder.languageSettings.activeLanguages
  );
  const isBuilder = document.getElementById("BuilderContent");

  useEffect(() => {
    if (colors) {
      const shopThemeColor = colors.find((x) => x.name === "SHOPTHEMECOLOR");

      if (shopThemeColor?.color) {
        document.documentElement.style.setProperty(
          "--shop-theme-color",
          shopThemeColor?.color
        );
      }
    }
  }, [colors]);

  useEffect(() => {
    if (colors) {
      const shopThemeColor = colors.find(
        (x) => x.name === "SHOPTHEMECOLORHOVER"
      );

      if (shopThemeColor?.color) {
        document.documentElement.style.setProperty(
          "--shop-theme-color-hover",
          shopThemeColor?.color
        );
      }
    }
  }, [colors]);

  useEffect(() => {
    if (website) {
      document.documentElement.style.setProperty(
        "--shop-border-radius",
        website.shop_b_radius ? website.shop_b_radius : "0px"
      );
    }
  }, [website]);

  const location = useLocation();
  if (
    !window.location.href.includes("/builder/") &&
    location.key === "default" &&
    location.pathname !== "/"
  ) {
    const pageNotFoundData = {
      name: "PageNotFound",
      message: location.pathname,
      stack:
        "Page with pathname " +
        location.pathname +
        " was not found. In ContentRenderer.tsx",
      componentStack: location.pathname,
      href: window.location.href,
    };
    logErrorToBackend(pageNotFoundData);
  }

  useEffect(() => {
    if (!isBuilder && pages) {
      if (pages.find((x) => x.page_type === "notFound")) {
        return;
      }

      const currentPathname = location.pathname;
      const languages = navigator.languages;
      let firstLanguage = languages[0];

      if (firstLanguage.includes("-")) {
        firstLanguage = firstLanguage.split("-")[0];
      }

      if (
        (currentPathname === "/" || currentPathname === "") &&
        pageLanguages &&
        pageLanguages.length > 0
      ) {
        const activeLanguages = pageLanguages.filter(
          (x) => x.visitor_state === 1
        );

        const homepages = pages
          .filter((page) => page.page_type === "frontPage")
          .filter((page) => {
            const pagesupDate = activeLanguages.filter((activeLanguage) => {
              if (activeLanguage.id === page.language.id) {
                return true;
              }
            });

            return pagesupDate.length > 0;
          });

        let selectedHomePage;

        if (website.use_browser_language === 1) {
          const homepageFirstLanguage = homepages.filter(
            (page) => page.language.locale === firstLanguage
          ) as any;

          if (!homepageFirstLanguage && languages.length >= 1) {
            let secondLanguage = languages[1];

            if (secondLanguage.includes("-")) {
              secondLanguage = secondLanguage.split("-")[0];
            }

            selectedHomePage = homepages.find(
              (page) => page.language.locale === secondLanguage
            );
          } else if (homepageFirstLanguage.length) {
            selectedHomePage = homepageFirstLanguage;

            navigate(`/${homepageFirstLanguage[0].language.locale}`);
          }
        } else if (website.use_browser_language === 0 && pageLanguages) {
          selectedHomePage = homepages.find(
            (page) =>
              page.language.locale ===
              pageLanguages.filter((x) => x.visitor_state === 1)[0].language
                .shortname
          );
        }

        if (!selectedHomePage) {
          selectedHomePage = homepages[0];
        }

        navigate(getPageLocaleUrl(selectedHomePage, pageLanguages));
      }
    }
  }, [pageLanguages, isBuilder, pages]);

  // Read cookie

  const renderComponents = () => {
    return webpageComponents?.map((componentId: number) => {
      return (
        <ContentRenderWebpageComponent
          key={componentId}
          componentId={componentId}
        />
      );
    });
  };
  // Empty dependency array to run this effect only once on mount

  return (
    <>
      {fontUrl && (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link href={fontUrl} rel="stylesheet" />
        </>
      )}
      <div
        className="ContentRenderer"
        style={{ overflow: isBuilder ? "visible" : "clip" }}
      >
        {renderComponents()}
      </div>
      <CookiePolicyComponent />
      {!isBuilder && <ShoppingCartExpired />}
    </>
  );
}
