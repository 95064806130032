import { useShoppingCart } from "../context/ShoppingCartContext";
import usePageLanguage from "../hooks/usePageLanguage";
import React from "react";

const languageTranslations = {
  et: {
    buyer: "OSTJA",
  },
  en: {
    buyer: "BUYER",
  },
};

export default function CheckoutBuyerPanelConfirmed({}) {
  const { buyer } = useShoppingCart();
  const lang = usePageLanguage();

  return (
    <div className="panel" id="checkoutBuyerPanel">
      <div className="panel-heading">
        <div className="panel-title">
          <span className="badge badge-square badge-primary ">2</span>
          <span className="panel-title-text">
            {languageTranslations[lang]?.buyer}
          </span>
        </div>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="col-xs-12">
            <div className="personName">
              {buyer.firstname} {buyer.surname}
            </div>
            <div className="personPhone">{buyer.phone}</div>
            <div className="personEmail">{buyer.email}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
