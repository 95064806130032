import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { AllComponents } from "./interfaces/base";

const BackgroundVideoComponent = React.memo(({ id }: any) => {
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as AllComponents;

  const [selectedTopPosition, setSelectedTopPosition] = useState(
    component.attributes.backgroundVideo?.topPosition
      ? component.attributes.backgroundVideo?.topPosition
      : "50"
  );

  const [selectedExtension, setSelectedExtension] = useState(
    component.attributes.backgroundVideo?.extension
      ? component.attributes.backgroundVideo?.extension
      : "mp4"
  );

  const [selectedLeftPosition, setSelectedLeftPosition] = useState(
    component.attributes.backgroundVideo?.leftPosition
      ? component.attributes.backgroundVideo?.leftPosition
      : "50"
  );
  const [selectedWidthPosition, setSelectedWidthPosition] = useState(
    component.attributes.backgroundVideo?.width
      ? component.attributes.backgroundVideo?.width
      : "120"
  );
  const [selectedHeightPosition, setSelectedHeightPosition] = useState(
    component.attributes.backgroundVideo?.height
      ? component.attributes.backgroundVideo?.height
      : "120"
  );
  const [selectedAutoplay, setSelectedAutoplay] = useState<boolean>(
    component.attributes.backgroundVideo?.autoplay
      ? component.attributes.backgroundVideo?.autoplay
      : true
  );
  const [selectedLoop, setSelectedLoop] = useState<boolean>(
    component.attributes.backgroundVideo?.loop
      ? component.attributes.backgroundVideo?.loop
      : true
  );
  const [selectedMuted, setSelectedMuted] = useState<boolean>(
    component.attributes.backgroundVideo?.muted
      ? component.attributes.backgroundVideo?.muted
      : true
  );

  const [selectedControls, setSelectedControls] = useState<boolean>(
    component.attributes.backgroundVideo?.controls
      ? component.attributes.backgroundVideo?.controls
      : true
  );

  const [selectedLazyLoad, setSelectedLazyLoad] = useState<boolean>(
    component.attributes.backgroundVideo?.lazyLoad
      ? component.attributes.backgroundVideo?.lazyLoad
      : false
  );

  useEffect(() => {
    setSelectedTopPosition(
      component.attributes.backgroundVideo?.topPosition
        ? component.attributes.backgroundVideo?.topPosition
        : "50"
    );
    setSelectedLeftPosition(
      component.attributes.backgroundVideo?.leftPosition
        ? component.attributes.backgroundVideo?.leftPosition
        : "50"
    );
    setSelectedWidthPosition(
      component.attributes.backgroundVideo?.width
        ? component.attributes.backgroundVideo?.width
        : "120"
    );
    setSelectedHeightPosition(
      component.attributes.backgroundVideo?.height
        ? component.attributes.backgroundVideo?.height
        : "120"
    );
    setSelectedAutoplay(
      component.attributes.backgroundVideo?.hasOwnProperty("autoplay")
        ? component.attributes.backgroundVideo?.autoplay
        : true
    );
    setSelectedLoop(
      component.attributes.backgroundVideo?.hasOwnProperty("loop")
        ? component.attributes.backgroundVideo?.loop
        : true
    );
    setSelectedMuted(
      component.attributes.backgroundVideo?.hasOwnProperty("muted")
        ? component.attributes.backgroundVideo?.muted
        : true
    );

    setSelectedControls(
      component.attributes.backgroundVideo?.hasOwnProperty("controls")
        ? component.attributes.backgroundVideo?.controls
        : false
    );

    setSelectedLazyLoad(
      component.attributes.backgroundVideo?.hasOwnProperty("lazyLoad")
        ? component.attributes.backgroundVideo?.lazyLoad
        : false
    );

    setSelectedExtension(
      component.attributes.backgroundVideo?.hasOwnProperty("extension")
        ? component.attributes.backgroundVideo?.extension
        : "mp4"
    );
  }, [component.attributes.backgroundVideo]);

  return (
    <div
      style={{
        pointerEvents: "none",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {component.attributes.backgroundVideo &&
        component.attributes.backgroundVideo.url && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",

              top: 0,
              left: 0,
              overflow: "hidden",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              zIndex: -1,
            }}
          >
            {selectedExtension === "mp4" && (
              <>
                testietksejtlksejtlksej
                <video
                  autoPlay={selectedAutoplay}
                  muted={selectedMuted}
                  loop={selectedLoop}
                  style={{
                    zIndex: 999,
                    position: "absolute",
                    top: `${selectedTopPosition}%`,
                    left: `${selectedLeftPosition}%`,
                    width: `${selectedWidthPosition}vw`,
                    height: `
                ${selectedHeightPosition}vh
                
              `,
                    transform: "translate(-50%, -50%)",
                  }}
                  src={component.attributes.backgroundVideo.url}
                />
              </>
            )}

            {(selectedExtension === "youtube" ||
              selectedExtension === "vimeo") &&
              component.attributes.backgroundVideo.extension !== undefined &&
              component.attributes.backgroundVideo.extension !== "mp4" && (
                <iframe
                  loading={selectedLazyLoad ? "lazy" : "eager"}
                  scrolling="no"
                  frameBorder="0"
                  style={{
                    position: "absolute",
                    top: `${selectedTopPosition}%`,
                    left: `${selectedLeftPosition}%`,
                    width: `${selectedWidthPosition}vw`,
                    height: `
                ${selectedHeightPosition}vh
                
              `,
                    transform: "translate(-50%, -50%)",
                  }}
                  allow="fullscreen; picture-in-picture"
                  allowFullScreen
                  src={`${
                    component.attributes.backgroundVideo.url
                  }?api=1&autoplay=${
                    selectedAutoplay === true ? "1" : "0"
                  }&loop=${
                    selectedLoop === true ? "1" : "0"
                  }&byline=0&title=0&muted=${
                    selectedMuted === true ? "1" : "0"
                  }&controls=${
                    component.attributes.backgroundVideo.controls
                  }&playlist=${
                    component.attributes.backgroundVideo.url.split("/embed/")[1]
                  }&mute=${selectedMuted === true ? "1" : "0"}`}
                />
              )}
          </div>
        )}
    </div>
  );
});

export default BackgroundVideoComponent;
