import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import { useShoppingCart } from "./context/ShoppingCartContext";
import useEmblaCarousel from "embla-carousel-react";
import usePageLanguage from "./hooks/usePageLanguage";
import { getPageLocaleUrl, pf } from "./utilities/helpers";
import AutoScroll from "embla-carousel-auto-scroll";
import AutoPlay from "embla-carousel-autoplay";

import "./MediaSliderComponent.css";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

const languageTranslations = {
  et: {
    view_product: "VAATA TOODET",
  },
  en: {
    view_product: "VIEW PRODUCT",
  },
};

export default function ProductSliderComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as any;
  const { locationProducts } = useShoppingCart();
  const lang = usePageLanguage();


  const languages = useAppSelector((state) => state.builder.languageSettings.activeLanguages);

  const isBuilder = document.getElementById("BuilderContent");

  const [productVariable, setProductVariable] = useState(
    component.attributes.productVariable
      ? component.attributes.productVariable
      : "category"
  );

  const containerRef = useRef<any>(null);

  const [slideCount, setSlidecount] = useState(
    component.attributes.styles.backgroundImage
      ? component.attributes.styles.backgroundImage.split(",").length
      : 0
  );

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [thumbsAllowed, setThumbsAllowed] = useState(
    component.attributes.thumbs ? component.attributes.thumbs : false
  );
  const logSlidesInView = useCallback((emblaApi: any) => {
    // Set the currently active selected index
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });

  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(
    {
      loop: component.attributes.loop ? component.attributes.loop : false,
      align: component.attributes.slideAlign
        ? component.attributes.slideAlign
        : "center",
    },

    // @ts-ignore
    component.attributes &&
      component.attributes.autoplay &&
      component.attributes.autoplay !== "none"
      ? [
          component.attributes.autoplay == "autoplay"
            ? AutoPlay({
                playOnInit: true,
                delay: component.attributes.autoplayInterval
                  ? component.attributes.autoplayInterval
                  : 3000,
                stopOnInteraction: false,
                stopOnMouseEnter: true,
              })
            : AutoScroll({
                speed: component.attributes.autoscrollSpeed
                  ? component.attributes.autoscrollSpeed
                  : 1,
                stopOnInteraction: false,
              }),
        ]
      : []
  );

  useEffect(() => {
    if (emblaMainApi) {
      emblaMainApi.on("slidesInView", logSlidesInView);
    }
  }, [emblaMainApi, logSlidesInView]);

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect);
    emblaMainApi.on("reInit", onSelect);
  }, [emblaMainApi, onSelect, setSelectedIndex]);

  const [productCategory, setProductCategory] = useState(
    component.attributes.productCategory
      ? component.attributes.productCategory
      : null
  );

  const pages = useAppSelector((state) => state.builder.pages.list);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const productPage = pages.find(
    (page) => page.page_type === "product" && page.language.locale === lang
  );

  var languageProductHref = productPage
    ? getPageLocaleUrl(productPage, languages).replace(/:id$/, "")
    : "/et/toode/";
  if (languageProductHref.substring(0, 1) !== "/") {
    languageProductHref = "/" + languageProductHref;
  }

  function categoryExistsInProduct(product, categoryIds) {
    if (!categoryIds || !categoryIds.length) {
      return false;
    }

    const productCategories = product.productCategories;

    // CategoryIDs is an array of ID-s, we need to check, if any of these ID's exist in productCategories

    return productCategories.some((category) =>
      categoryIds.includes(category.id)
    );
  }

  useEffect(() => {
    if (
      component.attributes.productVariable === "name" &&
      component.attributes.productId.length > 0
    ) {
      const products = locationProducts
        .filter((x) =>
          // ProductId is an array of ID-s, we need to check if x.id is in it
          component.attributes.productId.includes(x.id)
        )
        .sort((a, b) => {
          // Return the same order as the component.attributes.productId is in

          return (
            component.attributes.productId.indexOf(a.id) -
            component.attributes.productId.indexOf(b.id)
          );
        });
      setSelectedProducts(products);
      setSlidecount(products.length);
    }

    if (component.attributes.productVariable === "category") {
      // Filter products by category. Category is an array of ID-s

      const sortOption = component.attributes.categorySort;

      // We need to check if product has the category id in it, and if so return it.
      // Also each product can have mutliple categories, so if product already exists, we dont need to add it to our product array again

      const products = locationProducts
        .filter((x) => {
          return categoryExistsInProduct(x, component.attributes.categoryId);
        })
        .sort((a, b) => {
          // Sort by x.product.name
          if (sortOption === "a-z" || !sortOption) {
            return a.product.name.localeCompare(b.product.name);
          } else if (sortOption === "z-a") {
            return b.product.name.localeCompare(a.product.name);
          } else if (sortOption === "cheapest") {
            return (
              Number(a.price_with_discounts) - Number(b.price_with_discounts)
            );
          } else if (sortOption === "expensive") {
            return (
              Number(b.price_with_discounts) - Number(a.price_with_discounts)
            );
          } else {
            return a.name.localeCompare(b.name);
          }
        });

      setSelectedProducts(products);
      setSlidecount(products.length);
    }
  }, [
    locationProducts,
    productVariable,
    productCategory,
    component,
    component.attributes,
    component.attributes.productCategory,
  ]);

  const [emblaContainerWidth, setEmblaContainerWidth] = useState(0);

  const scrollPrev = useCallback(() => {
    if (emblaMainApi) {
      emblaMainApi.scrollPrev();
      setSelectedIndex(emblaMainApi.selectedScrollSnap());
    }
  }, [emblaMainApi]);

  const scrollNext = useCallback(() => {
    if (emblaMainApi) {
      emblaMainApi.scrollNext();
      setSelectedIndex(emblaMainApi.selectedScrollSnap());
    }
  }, [emblaMainApi]);

  useEffect(() => {
    if (containerRef.current?.clientWidth) {
      setEmblaContainerWidth(containerRef.current?.clientWidth);
    }
  }, [containerRef, containerRef.current?.clientWidth]);

  return (
    <Div
      id={id.toString()}
      className={
        (attributes.classes ?? "") +
        " builder-component builder-component__productSlider" +
        (editorId === id ? " builder-component--active" : "")
      }
      style={{ ...attributes.styles, maxWidth: "100%" }}
      ref={containerRef}
    >
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations.filter(
          (x) => x.sort === 1
        )}
      />
      <div
        className="embla"
        ref={emblaMainRef}
        style={{
          maxWidth: `100%`,
          position: "relative",
        }}
      >
        <div className="embla__container">
          {selectedProducts.map((selectedProduct) => (
            <div
              className="embla__slide"
              style={{
                maxWidth: `100%`,
                flex: `0 0 ${component.attributes.slideWidth}%`,
              }}
              key={selectedProduct.id}
            >
              <div className="" style={{ maxHeight: "500px" }}>
                <div className="web-product" style={{ margin: "0px" }}>
                  <a
                    className="product-img"
                    style={{
                      backgroundImage: selectedProduct.firstFileUrl
                        ? `url("${selectedProduct.firstFileUrl}")`
                        : "none",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                  >
                    <img
                      src={selectedProduct.firstFileUrl}
                      alt={
                        selectedProduct?.translations?.[lang].name + " pilt."
                      }
                      style={{ width: "100%" }}
                    ></img>
                    <div className="product-info-overlay">
                      <div
                        style={{ fontWeight: "400" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            selectedProduct?.translations?.[lang]
                              .shortDescription,
                        }}
                      />
                    </div>
                  </a>
                  <div className="padding-10 pb-20">
                    <div className="product-name">
                      <a href={`${languageProductHref}${selectedProduct.id}`}>
                        {selectedProduct?.translations?.[lang].name}&nbsp;
                      </a>
                    </div>
                    <div className="productPrice">
                      <span
                        style={
                          selectedProduct.adjustments &&
                          selectedProduct.adjustments?.length > 0
                            ? { textDecoration: "line-through" }
                            : {}
                        }
                      >
                        {pf(selectedProduct.price_total)}&euro;
                      </span>

                      {selectedProduct.price_with_discounts &&
                      selectedProduct.adjustments?.length > 0 ? (
                        <span className="discount-price-color">
                          {pf(Number(selectedProduct.price_with_discounts))}
                          &euro;
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <button
                        // href={
                        //   isBuilder
                        //     ? "#"
                        //     : `${languageProductHref}${selectedProduct.id}`
                        // }
                        className="btn btn-primary addProductToCart shop-controlled-border-radius single-product-button"
                        style={{
                          fontWeight: "600",
                          fontFamily: "Roboto, sans-serif",
                          padding: "6px 15px",
                        }}
                      >
                        {languageTranslations[lang]?.view_product}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {selectedProducts.length > 1 && component.attributes.dots ? (
          <div>
            <div
              className="embla__dots"
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginTop: "25px",
              }}
            >
              {selectedProducts.map((selectedProduct, index) => (
                <div
                  key={selectedProduct.id}
                  onClick={() => {
                    if (emblaMainApi) {
                      setSelectedIndex(index);
                      emblaMainApi.scrollTo(index);
                    }
                  }}
                  style={{
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    paddingBottom: "6px",
                    paddingTop: "6px",
                  }}
                >
                  <button
                    className="embla__dot"
                    style={{
                      height: "12px",
                      width: "12px",

                      background: "none",
                      border: "none",
                      outline: "none",
                      backgroundColor:
                        selectedIndex === index ? "#666" : "#ccc",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}

        {component.attributes.thumbs && (
          <>
            <button
              style={{
                display: selectedProducts.length > 1 ? "block" : "none",
                opacity: selectedProducts.length > 1 ? "1" : "0",
              }}
              className="embla__prev previous-carousel-button"
              onClick={scrollPrev}
            >
              <ArrowBackIos style={{ fontSize: "16px", marginLeft: "2px" }} />
            </button>
            <button
              style={{
                display: selectedProducts.length > 1 ? "block" : "none",
                opacity: selectedProducts.length > 1 ? "1" : "0",
              }}
              className="embla__next previous-carousel-button"
              onClick={scrollNext}
            >
              <ArrowForwardIos
                style={{ fontSize: "16px", marginRight: "2px" }}
              />
            </button>
          </>
        )}
      </div>
    </Div>
  );
}
