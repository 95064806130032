import { useShoppingCart } from "../context/ShoppingCartContext";
import React from "react";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import "./ProductServicesCartItem.css";
import usePageLanguage from "../hooks/usePageLanguage";

type ProductServicesCartItemProps = {
  id: number;
  quantity: number;
};

export default function ProductServicesCartItem({
  id,
  quantity,
}: ProductServicesCartItemProps) {
  const {
    locationProducts,
    decreaseCartQuantity,
    setCartQuantity,
    increaseCartQuantity,
    removeFromCart,
  } = useShoppingCart();

  const locationProduct = locationProducts.find((i) => i.id === id);
  const lang = usePageLanguage();

  if (locationProduct == null) return null;

  return (
    <div className="productServiceCartItem">
      <div className="productsServiceCartItemImage">
        <span style={{ fontSize: "12px" }}>image</span>
      </div>

      <div className="productServiceCartItemName">
        <span>{locationProduct?.translations?.[lang].name}</span>

        <div className="productServiceCartItemQuantity">
          <RemoveIcon
            className="productServicesCartButton"
            style={{ cursor: "pointer", fontSize: "21px" }}
            onClick={() => decreaseCartQuantity(id)}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              name="quantity"
              value={quantity}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCartQuantity(id, +e.target.value)
              }
              style={{
                display: "inline-block",
                width: "20px",
                backgroundColor: "transparent",
                border: "none",
                textAlign: "right",
              }}
            />{" "}
            {locationProduct.unit_shortname}{" "}
          </div>
          <AddIcon
            className="productServicesCartButton"
            style={{ cursor: "pointer", fontSize: "21px", marginLeft: "5px" }}
            onClick={() => increaseCartQuantity(id)}
          />
        </div>
      </div>

      {/* <div className="cartOrderProductRowPrice">
        {pf(locationProduct.price_total * quantity)} &euro;
      </div>
      <div className="cartOrderProductRowDelete">
        <FontAwesomeIcon icon={faTrashAlt} onClick={() => removeFromCart(id)} />
      </div> */}

      {/* 
      <div className="cartOrderProductQuantity">
        <RemoveIcon
          className="icon clickable padding-5 fs-14"
          onClick={() => decreaseCartQuantity(id)}
        />
        <input
          type="text"
          name="quantity"
          value={quantity}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCartQuantity(id, +e.target.value)
          }
          style={{
            display: "inline-block",
            width: "30px",
            backgroundColor: "transparent",
            border: "none",
            textAlign: "right",
            fontWeight: "bold",
          }}
        />{" "}
        {locationProduct.unit_shortname}{" "}
        <AddIcon
          className="icon clickable padding-5 fs-14"
          onClick={() => increaseCartQuantity(id)}
        />
      </div>
      <div className="cartOrderProductRowPrice">
        {pf(locationProduct.price_total * quantity)} &euro;
      </div>
      <div className="cartOrderProductRowDelete">
        <FontAwesomeIcon icon={faTrashAlt} onClick={() => removeFromCart(id)} />
      </div> */}
    </div>
  );
}
