import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import {
  LocationProduct,
  useShoppingCart,
} from "./context/ShoppingCartContext";
import { SingleProductComponent as SingleProductComponent_ } from "./interfaces/base";
import usePageLanguage from "./hooks/usePageLanguage";
import { getPageLocaleUrl, pf } from "./utilities/helpers";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

const languageTranslations = {
  et: {
    view_product: "VAATA TOODET",
  },
  en: {
    view_product: "VIEW PRODUCT",
  },
};

export default function SingleProductComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const lang = usePageLanguage();

  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as SingleProductComponent_;

  const [selectedProduct, setSelectedProduct] =
    useState<LocationProduct | null>(null);

  const isBuilder = document.getElementById("BuilderContent");

  const languages = useAppSelector(
    (state) => state.builder.languageSettings.activeLanguages
  );

  const { locationProducts } = useShoppingCart();

  useEffect(() => {
    const locationSelect = locationProducts.find(
      (x) => x.id === component.attributes.productId
    );

    if (locationSelect) {
      setSelectedProduct(locationSelect);
    }
  }, [component, locationProducts]);

  const productItemRef = useRef<HTMLDivElement>(null);
  const pages = useAppSelector((state) => state.builder.pages.list);

  const locale = usePageLanguage();

  const productPage = pages.find(
    (page) => page.page_type === "product" && page.language.locale === lang
  );
  var languageProductHref = productPage
    ? getPageLocaleUrl(productPage, languages).replace(/:id$/, "")
    : "/et/toode/";
  if (languageProductHref.substring(0, 1) !== "/") {
    languageProductHref = "/" + languageProductHref;
  }

  return (
    <Div
      id={id.toString()}
      data-component-type="singleProduct"
      className={
        (attributes.classes ?? "") +
        " builder-component builder-component__singleProduct" +
        (editorId === id ? " builder-component--active" : "")
      }
      style={{
        ...attributes.styles,
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations.filter(
          (x) => x.sort === 1
        )}
      />

      {!selectedProduct && (
        <div
          style={{
            minHeight: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#fafafa",
          }}
        >
          Please select a product.
        </div>
      )}

      {selectedProduct && (
        <div className="" style={{ maxHeight: "500px" }}>
          <div className="web-product" ref={productItemRef}>
            <a
              href={
                isBuilder ? "#" : `${languageProductHref}${selectedProduct.id}`
              }
              className="product-img"
              style={{
                backgroundImage: selectedProduct.firstFileUrl
                  ? `url("${selectedProduct.firstFileUrl}")`
                  : "none",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              <img
                src={selectedProduct.firstFileUrl}
                alt={selectedProduct?.translations?.[lang].name + " pilt."}
                style={{ width: "100%" }}
              ></img>
              <div className="product-info-overlay">
                <div
                  style={{ fontWeight: "400" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedProduct?.translations?.[lang].shortDescription,
                  }}
                />
              </div>
            </a>
            <div className="padding-10 pb-20">
              <div className="product-name">
                <a href={`${languageProductHref}${selectedProduct.id}`}>
                  {selectedProduct?.translations?.[lang].name}&nbsp;
                </a>
              </div>
              <div className="productPrice">
                <span
                  style={
                    selectedProduct.adjustments &&
                    selectedProduct.adjustments?.length > 0
                      ? { textDecoration: "line-through" }
                      : {}
                  }
                >
                  {pf(selectedProduct.price_total)}&euro;
                </span>

                {selectedProduct.price_with_discounts &&
                selectedProduct.adjustments?.length > 0 ? (
                  <span className="discount-price-color">
                    {pf(Number(selectedProduct.price_with_discounts))}&euro;
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div>
                <a
                  href={
                    isBuilder
                      ? "#"
                      : `${languageProductHref}${selectedProduct.id}`
                  }
                  className="btn btn-primary addProductToCart shop-controlled-border-radius single-product-button"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Roboto, sans-serif",
                    padding: "6px 15px",
                  }}
                >
                  {languageTranslations[locale]?.view_product}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </Div>
  );
}
