import { useState, useEffect, useCallback } from "react";
import { openDB } from "idb";
import { logErrorToBackend } from "../utilities/helpers";

// Initialize DB when needed
async function initDB() {
  console.log("initializing IndexedDB...");
  return openDB("database", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("keyval")) {
        console.log("creating object store 'keyval'...");
        db.createObjectStore("keyval");
      }
    },
  });
}

const isLocalStorageAvailable = () => {
  try {
    const x = "__storage_test__";
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    console.log("LocalStorage is available.");
    return true;
  } catch (e) {
    console.log("LocalStorage is not available.");
    return false;
  }
};

export function useLocalStorage<T>(key: string, initialValue: T | (() => T)) {
  const [value, setValue] = useState<T>(() => {
    if (key !== "locationProducts") {
      const jsonValue = localStorage.getItem(key);

      try {
        if (jsonValue != null) return JSON.parse(jsonValue);
      } catch (error) {}

      // Handle lazy initialization
      if (typeof initialValue === "function") {
        return (initialValue as () => T)();
      } else {
        return initialValue;
      }
    }

    // For IndexedDB-based storage, start with initialValue
    if (typeof initialValue === "function") {
      return (initialValue as () => T)();
    } else {
      return initialValue;
    }
  });

  useEffect(() => {
    const loadFromIndexedDB = async () => {
      if (key === "locationProducts") {
        try {
          const db = await initDB();
          const storedValue = await db.get("keyval", key);

          if (storedValue) {
            setValue(JSON.parse(storedValue));
          }
        } catch (error) {}
      }
    };

    loadFromIndexedDB();
  }, [key]);

  const saveValue = useCallback(async () => {
    if (key === "locationProducts") {
      try {
        throw new Error("localStorage limit reached");

        // localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        try {
          const db = await initDB();
          console.log("Saving value to IndexedDB for key:", key);
          await db.put("keyval", JSON.stringify(value), key);
        } catch (dbError) {
          logErrorToBackend({
            name: "IndexedDB",
            message: dbError,
            stack: `Unable to write to IndexedDB for key ${key}`,
            componentStack: "useLocalStorage",
            href: window.location.href,
          });
        }
      }
    } else if (isLocalStorageAvailable()) {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.error("Error saving to localStorage:", error);
        logErrorToBackend({
          name: "LocalStorage",
          message: error,
          stack: `Unable to write to localStorage for key ${key}`,
          componentStack: "useLocalStorage",
          href: window.location.href,
        });
      }
    } else {
      logErrorToBackend({
        name: "LocalStorage",
        message: "LocalStorage is not available",
        stack: `Unable to write to localStorage for key ${key}`,
        componentStack: "useLocalStorage",
        href: window.location.href,
      });
    }
  }, [key, value]);

  useEffect(() => {
    saveValue();
  }, [key, value, saveValue]);

  return [value, setValue] as [typeof value, typeof setValue];
}
