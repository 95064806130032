import React, { useState, useRef, useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
import { TextComponent as TextComponent_ } from "./interfaces/base";
import styled from "@emotion/styled";
import RenderChildren from "./RenderChildren";
import BackgroundVideoComponent from "./BackgroundVideoComponent";
import useLazyLoad from "./utilities/useLazyLoad";
import { EditorContent } from "@tiptap/react";

import Paragraph from "@tiptap/extension-paragraph";
import FontFamily from "@tiptap/extension-font-family";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

interface Props {
  id: number;
  attributes: TextComponent_["attributes"];
  editor: JSX.Element;
  childElements: any;
  childWebpageComponentRelations: any;
}

export default function ContentComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: Props) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const typographies = useAppSelector((state) => state.builder.typographies);
  const colors = useAppSelector((state) => state.builder.colors);
  const [style, setStyle] = useState<React.CSSProperties>();

  useEffect(() => {
    const compTypography = typographies?.find(
      (x: any) => x.id === attributes.typography_id
    );

    if (compTypography) {
      setStyle({
        fontSize: compTypography.font_size + compTypography.font_size_unit,
        fontFamily: compTypography.font_family,
        fontStyle: compTypography.font_style,
        fontWeight: compTypography.font_weight,
        letterSpacing: compTypography.letter_spacing,
        lineHeight: compTypography.line_height,
        color: colors?.find((x: any) => x.id === compTypography.color_id)
          ?.color,
      });
    }
  }, [typographies, attributes]);

  const [backgroundImage, setBackgroundImage] = useState(
    attributes.styles.backgroundImage ? attributes.styles.backgroundImage : ""
  );

  const editor = useAppSelector((state) => state.builder?.editor?.editor);

  /// Change the editor content to content value we get from attributes.text

  const isBuilder = document.getElementById("BuilderContent");

  const textElementRef = useRef<HTMLButtonElement>(null);
  const loaded = useLazyLoad(textElementRef, backgroundImage);

  Paragraph.configure({
    HTMLAttributes: {
      class: "builder-paragraph",
    },
  });

  FontFamily.configure({
    types: ["textStyle"],
  });

  const textContent = (
    <span
      dangerouslySetInnerHTML={{ __html: attributes.text }}
      // onMouseUp={(e) => setMouseUp(e)}
      // onKeyDown={(e) => setKeyDown(e)}
      // onBlur={(e) => setOnBlur(e)}
    ></span>
  );

  const tags: { [key: string]: JSX.Element } = {
    h1: (
      <h1
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
        }}
      >
        {textContent}
      </h1>
    ),
    h2: (
      <h2
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
        }}
      >
        {textContent}
      </h2>
    ),
    h3: (
      <h3
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
        }}
      >
        {textContent}
      </h3>
    ),
    h4: (
      <h4
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
        }}
      >
        {textContent}
      </h4>
    ),
    h5: (
      <h5
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
        }}
      >
        {textContent}
      </h5>
    ),
    h6: (
      <h6
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: !isBuilder
            ? loaded
              ? loaded.map((src) => `url("${src}")`).join(", ")
              : "none"
            : attributes.styles.backgroundImage,
        }}
      >
        {textContent}
      </h6>
    ),
    p: (
      <p
        style={{
          ...style,
          ...attributes.styles,
          backgroundImage: loaded
            ? loaded.map((src) => `url("${src}")`).join(", ")
            : "none",
        }}
      >
        {textContent}
      </p>
    ),
  };

  return (
    <Div
      ref={textElementRef}
      id={id.toString()}
      className={
        (attributes.classes ?? "") +
        " builder-component relative builder-component__content" +
        (editorId === id ? " builder-component--active" : "")
      }
      data-style={attributes}
      spellCheck="false"
      data-component-type="ContentComponent"
      style={{
        fontWeight: "400",
        position: "relative",
      }}
    >
      <div style={{ position: "relative", overflow: "hidden" }}>
        <BackgroundVideoComponent id={id} />
      </div>

      {!isBuilder && (
        // <div style={style} dangerouslySetInnerHTML={{ __html: content }}></div>
        <>
          {
            tags[
              typographies?.find((x: any) => x.id === attributes.typography_id)
                ?.tag ?? "p"
            ]
          }
        </>
      )}

      {editorId !== id && isBuilder && (
        // <div style={style} dangerouslySetInnerHTML={{ __html: content }}></div>
        <>
          {
            tags[
              typographies?.find((x: any) => x.id === attributes.typography_id)
                ?.tag ?? "p"
            ]
          }
        </>
      )}

      {isBuilder && editorId === id && editor && (
        <>
          <div
            className="builder-component_text-data"
            style={{
              ...style,
              ...attributes.styles,
              backgroundImage: !isBuilder
                ? loaded
                  ? loaded.map((src) => `url("${src}")`).join(", ")
                  : "none"
                : attributes.styles.backgroundImage,
            }}
          >
            <EditorContent
              id={id + "_content"}
              content={editor.getHTML()}
              editor={editor}
            ></EditorContent>
          </div>
        </>
      )}

      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />
    </Div>
  );
}
