import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import "./ProductImagesComponent.css";
import { EmblaOptionsType } from "embla-carousel";
import { ThumbFullscreen } from "./ProductImagesComponentThumbsFullscreen";
import { ArrowBackIos, Close } from "@mui/icons-material";
import { ArrowForwardIos } from "@mui/icons-material";

export default function ProductImagesComponentFullscreen({
  handleFullScreen,
  slides,
  activeIndex,
}: any) {
  const OPTIONS: EmblaOptionsType = {
    loop: true,
  };

  const [selectedIndex, setSelectedIndex] = useState(activeIndex);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({
    loop: true,
  });
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });

  if (emblaMainApi) {
    emblaMainApi.scrollTo(selectedIndex);
  }

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect);
    emblaMainApi.on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);
  const scrollPrev = useCallback(() => {
    if (emblaMainApi) emblaMainApi.scrollPrev();
  }, [emblaMainApi]);

  const scrollNext = useCallback(() => {
    if (emblaMainApi) emblaMainApi.scrollNext();
  }, [emblaMainApi]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect);
    emblaMainApi.on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        handleFullScreen(false);
      }
    });

    document.addEventListener("keydown", (event) => {
      if (event.key === "ArrowLeft") {
        scrollPrev();
      } else if (event.key === "ArrowRight") {
        scrollNext();
      }
    });

    return () => {
      document.removeEventListener("keydown", (event) => {
        if (event.key === "ArrowLeft") {
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          scrollNext();
        }
      });

      document.removeEventListener("keydown", (event) => {
        if (event.key === "Escape") {
          handleFullScreen(false);
        }
      });
    };
  }, [emblaMainApi, scrollNext, scrollPrev, handleFullScreen]);

  return (
    <div className="ProductImageFullscreenContainer" onClick={handleFullScreen}>
      <div onClick={handleFullScreen} className="ProductFullscreenClose">
        <Close style={{ fontSize: "32px" }} />
      </div>

      <div
        className="fullscreen__embla"
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "auto",
        }}
      >
        <div
          className="embla__viewport"
          ref={emblaMainRef}
          style={{
            position: "relative",
            marginTop: "auto",
            width: "100%",
            height: "80%",
          }}
        >
          <div
            className="embla__container"
            style={{ height: "100%", width: "100%" }}
          >
            {slides.map((slide, index) => {
              let src;

              if (slide.url) {
                src = slide.url;
              } else if (slide.src) {
                let urlSrc = slide.src;

                const urlStartIndex = urlSrc.indexOf('"') + 1; // Finding the index of the first quote mark
                const urlEndIndex = urlSrc.lastIndexOf('"'); // Finding the index of the last quote mark
                const extractedUrl = urlSrc.substring(
                  urlStartIndex,
                  urlEndIndex
                ); // Extracting the URL

                src = extractedUrl;
              }

              return (
                <div
                  className="embla__slide"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  style={{ height: "100%", width: "100%" }}
                  // onClick={handleFullScreen}
                >
                  <div
                    className="embla__slide__number"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <img
                      src={src}
                      alt="Product image"
                      // style={{ objectFit: "contain" }}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <button
            className="embla__prev"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              scrollPrev();
            }}
          >
            <ArrowBackIos style={{ fontSize: "16px", marginLeft: "2px" }} />
          </button>
          <button
            className="embla__next"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              scrollNext();
            }}
          >
            <ArrowForwardIos style={{ fontSize: "16px", marginRight: "2px" }} />
          </button>
        </div>

        <div
          className="embla-thumbs"
          style={{ marginTop: "auto", width: "100%", marginBottom: "10px" }}
        >
          <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
            <div className="embla-thumbs__container embla-thumbs__container_fullscreen">
              {slides.map((slide, index) => (
                <ThumbFullscreen
                  slide={slide}
                  key={index}
                  onClick={() => {
                    onThumbClick(index);
                  }}
                  selected={index === selectedIndex}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
