import { useAppSelector } from "../../../store/hooks";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { TextField } from "@mui/material";
import usePageLanguage from "../hooks/usePageLanguage";
import React from "react";

interface Props {
  setShowBuyerModal: (val: boolean) => void;
}

const languageTranslations = {
  et: {
    buyer: "OSTJA",
    edit: "MUUDA",
  },
  en: {
    buyer: "BUYER",
    edit: "EDIT",
  },
};

export default function CheckoutBuyerPanel({ setShowBuyerModal }: Props) {
  const { buyer } = useShoppingCart();
  let lang = usePageLanguage();

  return (
    <div className="panel" id="checkoutBuyerPanel">
      <div className="panel-heading">
        <div className="panel-title">
          <span className="badge badge-square badge-primary ">2</span>
          <span className="panel-title-text">
            {languageTranslations[lang]?.buyer}
          </span>
          {Object.keys(buyer).length != 0 && (
            <div
              className="pull-right color-brown clickable"
              style={{ fontSize: "14px" }}
              onClick={() => setShowBuyerModal(true)}
            >
              {languageTranslations[lang]?.edit}
            </div>
          )}
        </div>
      </div>
      <div className="panel-body">
        {buyer.type === "organization" && (
          <div className="row">
            <div className="col-xs-12">
              <div className="personName">{buyer.organization_name}</div>
              <div className="personPhone">
                {buyer.organization_reg_nr} {buyer.organization_KMKR_number}
              </div>
              <div className="personPhone">{buyer.organization_phone} </div>
              <div className="personEmail">{buyer.organization_address}</div>
              <div className="personEmail">{buyer.organization_email}</div>
            </div>
          </div>
        )}

        {Object.keys(buyer).length != 0 && buyer.type !== "organization" && (
          <div className="row">
            <div className="col-xs-12">
              <div className="personName">
                {buyer.firstname} {buyer.surname}
              </div>
              <div className="personPhone">{buyer.phone}</div>
              <div className="personEmail">{buyer.email}</div>
            </div>
          </div>
        )}
        {Object.keys(buyer).length == 0 && (
          <div>
            <TextField
              InputLabelProps={{ style: { color: "#333", fontSize: "14px" } }}
              fullWidth
              label="E-post"
              variant="standard"
              value=""
              onClick={() => setShowBuyerModal(true)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
