import React, { useEffect, useState } from "react";
import { ProductCategory } from "../context/ShoppingCartContext";
import usePageLanguage from "../hooks/usePageLanguage";
import "./Category.css";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";

interface Props {
  productCategory: ProductCategory;
  productCategoryId: number | null;
  setProductCategoryId: (val: number) => void;
  depthLevel?: number;
  productCategories: ProductCategory[];
}

function CategoryComponent({
  productCategory,
  productCategoryId,
  setProductCategoryId,
  depthLevel = 0,
  productCategories,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const lang = usePageLanguage();
  const location = useLocation();

  const [isHovered, setIsHovered] = useState(false);

  const typographies = useAppSelector((state) => state.builder.typographies);
  const colors = useAppSelector((state) => state.builder.colors);

  const filteredTypography = typographies.filter((x) => {
    if (
      x.active_components &&
      x.active_components.includes("productsCategory")
    ) {
      return x;
    } else {
      return null;
    }
  });

  const typographyStyles =
    filteredTypography.length > 0
      ? {
          fontFamily: filteredTypography[0].font_family,
          fontSize:
            filteredTypography[0].font_size +
            filteredTypography[0].font_size_unit,
          fontStyle: filteredTypography[0].font_style,
          fontWeight: filteredTypography[0].font_weight,
          letterSpacing: filteredTypography[0].letter_spacing,
          lineHeight: filteredTypography[0].line_height,
          color: colors.find((x) => x.id === filteredTypography[0].color_id)
            ?.color,

          ...(productCategoryId === productCategory.id && {
            color: colors.find(
              (x) => x.id === filteredTypography[0].link_hover_color_id
            )?.color,
          }),

          ...(isHovered && {
            color: colors.find(
              (x) => x.id === filteredTypography[0].link_hover_color_id
            )?.color,
          }),
        }
      : {};

  useEffect(() => {
    if (depthLevel === 0) {
      setOpen(false);
    } else if (depthLevel === 1) {
      setOpen(false);
      let categoryFound = productCategories.find((pc) => {
        return pc.id === productCategory.id;
      });

      if (categoryFound) {
        setOpen(true);
      }
    } else if (depthLevel === 2) {
      let categoryFound;

      productCategories.forEach((pc) => {
        if (pc.id === productCategory.id) {
          setOpen(true);
        } else if (pc.cashregisterChildren.length > 0) {
          const childElements = pc.cashregisterChildren;
          childElements.forEach((ce) => {
            if (ce.id === productCategory.id) {
              categoryFound = ce;
              setOpen(true);
            }
          });
        }
      });
    } else if (depthLevel === 3) {
      setOpen(true);
    }
  }, [depthLevel]);

  const onCategoryClick = (value: number) => {
    setProductCategoryId(value);
    setOpen(!open);
  };

  useEffect(() => {
    if (productCategory.id === Number(location.search.split("?category=")[1])) {
      onCategoryClick(productCategory.id);
      setOpen(true);
    }

    if (productCategory.cashregisterChildren.length > 0) {
      productCategory.cashregisterChildren.map((childCategory) => {
        if (
          childCategory.id === Number(location.search.split("?category=")[1])
        ) {
          onCategoryClick(childCategory.id);
          setOpen(true);
        }
      });
    }
  }, []);

  return (
    <div className="categoryDropdown categoryItem">
      <div style={{ display: "flex", gap: "4px" }}>
        <a
          onClick={() => {
            onCategoryClick(productCategory.id);
          }}
          key={productCategory.id}
          className={`category-name collapsed${
            productCategoryId === productCategory.id ? " active" : ""
          }`}
          data-toggle="collapse"
          data-value={productCategory.id}
          aria-expanded={open ? "true" : "false"}
          role="button"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={typographyStyles}
        >
          {productCategory.locale_translations[lang].name}
        </a>
      </div>
      {productCategory.cashregisterChildren.length > 0 && (
        <div className={`collapse${open ? " show" : ""} panel-container`}>
          {productCategory.cashregisterChildren.map((childCategory) => (
            <div
              key={childCategory.id}
              id={childCategory.id.toString()}
              role="menu"
            >
              <div className="">
                <CategoryComponent
                  productCategories={productCategories}
                  depthLevel={depthLevel}
                  productCategory={childCategory}
                  productCategoryId={productCategoryId}
                  setProductCategoryId={setProductCategoryId}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(CategoryComponent);
